/* eslint max-lines: ["error", {"max": 300, "skipBlankLines": true, "skipComments": true}] */
import { Component, router, Logger, store } from 'lib'
import relApi from 'Common/Service/RelApi/RelApi'
import util from 'Common/Util/Util'
import watchUtil from 'Container/Watch/Util/WatchUtil'
import riotBar from 'Common/Service/RiotBar/RiotBar'
import liveStats from 'Common/Service/LiveGameEvents/LiveGameEvents'
import Watch from 'Component/Watch/Watch'
import InformLoading from 'Component/Inform/Loading/InformLoading'
import InformBubble from 'Component/Inform/Bubble/InformBubble'
import { sortLeaguesByPriority } from '../../../Common/Service/RelApi/RelApi'
import analytics from 'Common/Service/Analytics/Analytics'
import locale from 'Common/Locale/Locale'

const refetchEventDetailsInterval = 45 * util.times.SECONDS
const refetchOnError = 30 * util.times.SECONDS

let eventDetailsTimeoutId = 0

const supportedChatPlatforms = ['twitch', 'nimotv', 'mildom', 'trovo']

class WatchLive extends Component {
  constructor () {
    super()
    this.log = new Logger(this.constructor.name)
    this.hl = locale.get()
  }

  componentDidMount () {
    this.addListeners()
  }

  componentWillMount () {
    this.leagueSlug = router.param('leagueSlug')
    this.streamId = router.param('streamId')
  }

  componentWillUnmount () {
    window.clearTimeout(eventDetailsTimeoutId)
    store.removeListener('liveEvents', this.liveEventsListener)
    store.removeListener('videoPlayer.timestamp', this.gamePhaseListener)

    this.refetcher && this.refetcher.stop()
  }

  addListeners () {
    store.onChange(
      'liveEvents',
      this.liveEventsListener = (newJson, oldJson) => {
        if (util.getDeepValue(newJson, 'events.length')) {
          // Live event priorities:
          // 1. Event for League explicitly in URL
          // 2. Event for League for default League based on region
          // 3. Event based on Esports API priorities

          const eventForLeagueSlug
            = this.leagueSlug
            && this.getEventForLeague(newJson.events, this.leagueSlug)

          if (eventForLeagueSlug) {
            return this.fetchEventDetails(eventForLeagueSlug.id)
          }

          const userRegion = riotBar.getRegionCode()
          const defaultLeagueForRegion = watchUtil.getDefaultLeagueByRegion(
            userRegion
          )
          const eventForDefaultLeague = this.getEventForLeague(
            newJson.events,
            defaultLeagueForRegion
          )

          if (eventForDefaultLeague) {
            return this.fetchEventDetails(eventForDefaultLeague.id)
          }

          this.fetchEventDetails(this.getPrioritizedEventId(newJson.events))
        }
        else if (oldJson && !oldJson.events || !oldJson) {
          // only redirect if user wasn't watching something
          this.log.info('No live games, redirect to Schedule')
          window.location.pathname = `/${this.hl}/schedule`
        }
      }
    )

    store.onChange(
      'videoPlayer.timestamp',
      this.gamePhaseListener = (timestamp) => {
        const isInGame = watchUtil.isInGame(timestamp)

        this.state.isInGame !== isInGame && this.setState({ isInGame })
      }
    )

    this.streamChangeHandler = (selectedStream) => {
      this.streamId = selectedStream.parameter
      this.updateUrl()
      this.setState({ selectedStream }, watchUtil.forceVideoPlayerReattach)
    }
  }

  handleOffsetChange (newOffset, statsStatus) {
    const prevOffset = store.get('watch.offset')
    const prevStatsStatus = store.get('watch.statsStatus')
    const sameOffset = prevOffset === newOffset
    const sameStatus = prevStatsStatus === statsStatus

    if (sameOffset && sameStatus) return

    !sameOffset
      && this.log.info('Offset change from', prevOffset, 'to', newOffset)
    !sameStatus
      && this.log.info(
        'Stats Status change from',
        prevStatsStatus,
        'to',
        statsStatus
      )

    store.set('watch.offset', newOffset)
    store.set('watch.statsStatus', statsStatus)
    if (statsStatus === 'disabled') {
      this.log.info('Disabling livestats')
      this.refetcher && this.refetcher.stop()
    }
    else {
      this.log.info('Enabling livestats')
      this.refetcher && this.refetcher.start()
    }
  }

  getPrioritizedEventId (events) {
    const sortedEvents = events.sort((eventA, eventB) =>
      sortLeaguesByPriority(eventA.league, eventB.league)
    )

    return sortedEvents[0].id
  }

  getEventForLeague (events, leagueSlug) {
    return events.find((event) => event.league.slug === leagueSlug)
  }

  updateUrl () {
    const url = '/live/' + this.leagueSlug + '/' + this.streamId

    router.updateUrl(url)
    store.set('watch.url', url)
  }

  setInitialGameTimestamp (gameId) {
    this.refetcher = liveStats.refetchInitialTimestamp(gameId)

    this.refetcher
      .onFetch((json) => {
        // got a 200 with no JSON body, we are waiting for the game to start
        if (!json) return this.log.info('Waiting for game to start...')

        const initialTimestamp = json.frames[0].rfc460Timestamp

        this.log.info('Initial timestamp set to ', initialTimestamp)
        store.set('watch.initialTimestamp', initialTimestamp)
        this.refetcher.stop() // stop fetching - we have a start time
      })
      .onError((error) => {
        this.log.error(error)
      })
  }

  getCurrentGame (games) {
    for (let i = 0; i < games.length; i++) {
      if (games[i].state === 'inProgress') return games[i]
    }
  }

  currentGameHasChanged (maybeNewCurrentGame) {
    return (
      !this.state.currentGame
      || this.state.currentGame.id !== maybeNewCurrentGame.id
    )
  }

  setCurrentGame (event) {
    if (event.type === 'match') {
      const currentGame = this.getCurrentGame(event.match.games)

      if (!currentGame) {
        return this.log.warn('No live game for matchId', event.id)
      }
      else if (this.currentGameHasChanged(currentGame)) {
        const teams = currentGame.teams.map((t) => t.id)

        analytics.trackEvent('live_match_viewed', {
          id: currentGame.id,
          teamIds: teams
        })
        // do not want to re-render until stream is initialized in fetchEventDetails
        this.state.currentGame = currentGame
        this.log.info('Current game has changed, resetting stats')
        store.set('watch.initialTimestamp', undefined)
        store.set('watch.live.finalTimestamp', undefined)
        this.refetcher && this.refetcher.stop()
        this.setInitialGameTimestamp(this.state.currentGame.id)
      }
    }
    else {
      // setting currentGame to undefined will force stats to not render
      this.state.currentGame = undefined
    }
  }

  fetchEventDetails (eventId) {
    window.clearTimeout(eventDetailsTimeoutId)

    return relApi
      .fetchEventDetails(eventId)
      .then((eventDetails) => {
        let event

        if (eventDetails.streams && eventDetails.streams.length) {
          event = eventDetails
        }
        else {
          this.refetchEventDetails(refetchEventDetailsInterval, eventId)
          return this.log.error('No streams found for event:', eventId)
        }

        this.setCurrentGame(event)

        const streams = util.isIE()
          ? watchUtil.removeProvidersWithoutIESupport(event.streams)
          : event.streams

        const selectedStream
          = this.state.selectedStream // keep current stream if it exists
          || watchUtil.getInitialPrioritizedStream(streams, this.streamId)

        const updatedStream = streams.find(
          (stream) =>
            stream.parameter === selectedStream.parameter
            && stream.provider === selectedStream.provider
        )

        if (
          this.leagueSlug !== event.league.slug
          || this.streamId !== updatedStream.parameter
        ) {
          this.leagueSlug = event.league.slug
          this.streamId = updatedStream.parameter
          this.updateUrl()
        }

        this.handleOffsetChange(updatedStream.offset, updatedStream.statsStatus)

        this.setState({
          selectedStream: updatedStream,
          event
        })

        this.refetchEventDetails(refetchEventDetailsInterval, eventId)
      })
      .catch((error) => {
        this.refetchEventDetails(refetchOnError, eventId)
        this.log.error(error)
      })
  }

  refetchEventDetails (interval, eventId) {
    eventDetailsTimeoutId = window.setTimeout(() => {
      this.fetchEventDetails(eventId)
    }, interval)
  }

  componentWillUpdate (nextProps, nextState) {
    watchUtil.statsDisabledLogging(this.state, nextState)
  }

  eventHasStats () {
    const {
      gameIsLive,
      offsetIsNotZero,
      isInGame
    } = watchUtil.getStatsDisabledConditions(this.state)

    return gameIsLive && offsetIsNotZero && isInGame
  }

  chatServiceAvailable () {
    return (
      supportedChatPlatforms.indexOf(this.state.selectedStream.provider) !== -1
    )
  }

  renderErrorOrLoading (error) {
    return (
      <main class={ this.constructor.name }>
        { error ? (
          <InformBubble theme="error" icon="error">
            { error.message }
          </InformBubble>
        )
          : <InformLoading style="height:200px"/>
        }
      </main>
    )
  }

  render () {
    const { event, selectedStream } = this.state

    if (!selectedStream || !event || this.state.error) {
      return this.renderErrorOrLoading(this.state.error)
    }

    return (
      <main class={ this.constructor.name }>
        <Watch
          watchType={ watchUtil.streamTypes.live }
          event={ event }
          gameId={ util.getDeepValue(this.state, 'currentGame.id') }
          selectedStream={ selectedStream }
          streams={ event.streams }
          streamChangeHandler={ this.streamChangeHandler }
          eventHasStats={ this.eventHasStats() }
          chatServiceAvailable={ this.chatServiceAvailable() }
        />
      </main>
    )
  }
}

export default WatchLive
