import { Component, Logger } from 'lib'

class WatchChat extends Component {
  constructor () {
    super()
    this.log = new Logger(this.constructor.name)
  }

  renderTwitchChat (providerId) {
    const srcURL
      = 'https://www.twitch.tv/embed/'
      + providerId
      + '/chat?darkpopout&parent='
      + window.location.hostname

    return (
      <iframe
        class="twitch-chat"
        frameborder="0"
        scrolling="yes"
        id={ providerId }
        src={ srcURL }
      />
    )
  }

  renderNimotvChat (providerId) {
    const srcURL = 'https://www.nimo.tv/embed/chat/' + providerId + '?_theme=2'

    return (
      <iframe
        class="nimotv-chat"
        frameborder="0"
        scrolling="yes"
        id={ providerId }
        src={ srcURL }
      />
    )
  }

  renderYoutubeChat () {
    // TODO: youtube chat?
    return
  }

  renderMildomChat (providerId) {
    const srcURL
      = 'https://www.mildom.com/popout/chat/' + providerId + '?ch_4=ljl'

    return (
      <iframe
        class="mildom-chat"
        frameborder="0"
        scrolling="yes"
        id={ providerId }
        src={ srcURL }
      />
    )
  }

  renderTrovoChat (streamId) {
    const srcUrl = 'https://trovo.live/chat/' + streamId

    return (
      <iframe
        class="trovo-chat"
        src={ srcUrl }
        id={ streamId }
        height="100%"
        width="100%"
        frameborder="0"
        scrolling="yes"
      />
    )
  }

  renderProviderChat (provider, providerId) {
    switch (provider) {
      case 'twitch':
        return this.renderTwitchChat(providerId)
      case 'youtube':
        return this.renderYoutubeChat(providerId)
      case 'nimotv':
        return this.renderNimotvChat(providerId)
      case 'mildom':
        return this.renderMildomChat(providerId)
      case 'trovo':
        return this.renderTrovoChat(providerId)
      default:
        this.log.error('No chat service for provider', provider)
        return
    }
  }

  render () {
    const { provider, providerId } = this.props

    if (!provider || !providerId) {
      return this.log.error(
        'Missing one or more mandatory properties: '
          + '\nprovider: '
          + provider
          + '\nproviderId: '
          + providerId
      )
    }

    return (
      <div class={ this.constructor.name }>
        { this.renderProviderChat(provider, providerId) }
      </div>
    )
  }
}

export default WatchChat
