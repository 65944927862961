import { Component, Logger, router, store } from 'lib'
import watchUtil from 'Container/Watch/Util/WatchUtil'
import videoPlayerUtil from 'Component/Video/Player/Util/VideoPlayerUtil'
import contentstack from 'Common/Service/Contentstack/Contentstack'
import locale from 'Common/Locale/Locale'
import device from 'Common/Device/Device'
import InformLoading from 'Component/Inform/Loading/InformLoading'
import InformBubble from 'Component/Inform/Bubble/InformBubble'
import VideoPlayer from 'Component/Video/Player/VideoPlayer'
import Icon from 'Component/Asset/Icon/Icon'
import Image from 'Component/Asset/Image/Image'
import analytics from 'Common/Service/Analytics/Analytics'

const initialUpNextVideos = 6
const numExtraVideosToShow = 4
const linkRe = /\b(https?:\/\/\S*)\b/gi

class Video extends Component {
  constructor () {
    super()
    this.log = new Logger(this.constructor.name)
    this.setState({
      numUpNextVideos: initialUpNextVideos,
      upNextIndex: 0
    })

    this.addListeners()
  }

  componentDidMount () {
    const videoId = router.param('videoId')

    this.fetchVideoDetail(videoId)

    // If linked from homepage, we pass in the list of videos instead of fetching
    this.props.homepage
      ? this.setState({ upNext: this.filterHomepage(this.props.homepage) })
      : this.fetchHomepage(videoId)
  }

  addListeners () {
    this.onClickLoadMore = () => {
      const numUpNextVideos = this.state.numUpNextVideos + numExtraVideosToShow

      this.setState({ numUpNextVideos })
    }

    this.loadVideo = (videoId) => {
      window.scrollTo(0, 0)
      this.updateUpNextIndex(this.state.upNext, videoId)
      this.fetchVideoDetail(videoId)
    }

    store.onChange(
      'videoPlayer.state',
      this.videoPlayerStateListener = (newState) => {
        if (
          newState === videoPlayerUtil.states.ended
          && this.state.upNext
          && this.state.upNext.length
        ) {
          this.loadVideo(this.state.upNext[this.state.upNextIndex].uid)
        }
      }
    )
  }

  filterHomepage (homepage) {
    // filter homepage for only videos
    return homepage.content_list.filter(
      (item) => item._content_type_uid === 'youtube_videos'
    )
  }

  fetchHomepage (videoId) {
    return contentstack
      .fetchHomepage(true)
      .then((homepage) => {
        const upNext = this.filterHomepage(homepage)

        this.updateUpNextIndex(upNext, videoId)
        this.setState({ upNext })
      })
      .catch((error) => {
        // do not want page to error if we can't render upNext section
        this.log.error(error)
      })
  }

  fetchVideoDetail (videoId) {
    return contentstack
      .fetchVideoDetail(videoId)
      .then((video) => {
        this.updateUrl(videoId)
        this.setState({ video })
      })
      .catch((error) => {
        this.setState({ error })
        this.log.error(error)
      })
  }

  updateUrl (videoId) {
    const url = '/video/' + videoId

    store.set('watch.url', url)
    router.updateUrl(url)
  }

  updateUpNextIndex (videoList, videoId) {
    let upNextIndex = videoList.findIndex((video) => video.uid === videoId)

    // Increment to get video after the current one playing. If the video is not
    // found in the search above, findIndex will return -1 and upNextIndex will
    // be set to 0
    upNextIndex++

    // Loop back to the beginning of the list if we've reached the end
    if (upNextIndex === videoList.length) {
      upNextIndex = 0
    }

    this.setState({ upNextIndex })
  }

  sanitizeDescription (description) {
    const sanitizedDescription = []
    const parts = description.split(linkRe)

    parts.map((part) => {
      sanitizedDescription.push(
        part.indexOf('http') === 0 ? (
          <a class="external-link" target="_blank" href={ part }>
            { part }
          </a>
        )
          : part

      )
    })

    return sanitizedDescription
  }

  renderVideoInfo (video) {
    return (
      <div class="video-info">
        <div class="title">{ video.title }</div>
        <div class="description">
          { this.sanitizeDescription(video.description) }
        </div>
      </div>
    )
  }

  renderUpNextVideo (video) {
    const thumbnailSrc
      = device.getSize() === 'medium' ? video.thumbnail_high : video.thumbnail_low

    analytics.trackEvent('up_next_content_viewed')

    const onClick = () => {
      this.loadVideo(video.uid)
    }

    return (
      <div class="video" onclick={ onClick } role="button">
        <Image class="thumbnail" size="348x195" src={ thumbnailSrc }/>
        <div class="info">
          <div class="title">{ video.title }</div>
        </div>
      </div>
    )
  }

  renderUpNextVideos (videoList) {
    const renderedVideoList = []
    const startIndex = this.state.upNextIndex
    const maxVideoIndex = Math.min(
      videoList.length,
      startIndex + this.state.numUpNextVideos
    )

    for (let i = startIndex; i < maxVideoIndex; i++) {
      renderedVideoList.push(this.renderUpNextVideo(videoList[i]))
    }

    return renderedVideoList
  }

  renderLoadMore () {
    return (
      <div class="load-more" role="button" onclick={ this.onClickLoadMore }>
        <div class="text">{ locale.translate('content.video.loadMore') }</div>
        <Icon name="arrow" direction={ 'down' }/>
      </div>
    )
  }

  renderUpNext (videoList) {
    const moreVideosToShow
      = this.state.numUpNextVideos <= videoList.length - this.state.upNextIndex

    return (
      <div class="up-next">
        <div class="header">{ locale.translate('content.video.upNext') }</div>
        <div class="video-list">{ this.renderUpNextVideos(videoList) }</div>
        { moreVideosToShow && this.renderLoadMore() }
      </div>
    )
  }

  renderErrorOrLoading (error) {
    return (
      <main class={ this.constructor.name }>
        { error ? (
          <InformBubble theme="error" icon="error">
            { error.message }
          </InformBubble>
        )
          : <InformLoading/>
        }
      </main>
    )
  }

  render () {
    const { video, upNext } = this.state

    if (!video || this.state.error) {
      return this.renderErrorOrLoading(this.state.error)
    }

    return (
      <main class={ this.constructor.name }>
        <VideoPlayer
          streamLocale={ video.locale }
          uuid={ video.video_id }
          provider="youtube"
          streamType={ watchUtil.streamTypes.vod }
        />
        <div class="lower-content">
          { this.renderVideoInfo(video) }
          { upNext && this.renderUpNext(upNext) }
        </div>
      </main>
    )
  }
}

export default Video
