import { Component, router, $ } from 'lib'
import Image from 'Component/Asset/Image/Image'
import locale from 'Common/Locale/Locale'
import util from 'Common/Util/Util'
import device from 'Common/Device/Device'
import VodsGameSelector from 'Component/Vods/GameSelector/VodsGameSelector'
import InformSpoiler from 'Component/Inform/Spoiler/InformSpoiler'
import informTooltip from 'Component/Inform/Tooltip/InformTooltip'

class VodsMatch extends Component {
  constructor () {
    super()
    this.addListeners()
  }

  componentWillMount () {
    this.setState({
      hideSpoilers: this.props.match.match.type === 'tiebreaker'
    })
  }

  addListeners () {
    this.link = (event) => {
      event.stopPropagation()
      event.preventDefault()

      if (device.getSize() === 'small') {
        this.preventMatchFromLoading(event) // only use VodsGameSelector for linking on small
      }
      else {
        const link = $(event.target)
          .closest('.link')
          .attr('href')

        router.go(link, event)
      }
    }

    this.showSpoilersHandler = () => {
      this.setState({ hideSpoilers: false })
    }

    this.showGames = (event) => {
      this.preventMatchFromLoading(event)
      // if the games are already showing, we want to close on click
      const matchId
        = !$(this.base).hasClass('show-games') && this.props.match.match.id

      this.props.showMatchGamesHandler
        && this.props.showMatchGamesHandler(matchId)
    }

    this.preventMatchFromLoading = (event) => {
      event.stopPropagation()
      event.preventDefault()
    }
  }

  renderSpoilerCover () {
    return (
      <div class="spoiler-cover">
        <InformSpoiler
          visible={ this.state.hideSpoilers }
          showSpoilersHandler={ this.showSpoilersHandler }
        />
      </div>
    )
  }

  renderTeam (team) {
    return (
      <div class="team">
        <div class="name">{ team.code }</div>
        <Image class="logo" src={ team.image } size="70"/>
      </div>
    )
  }

  renderMatch (match, isTiebreaker) {
    const matchElement = (
      <a
        class="wrapper link"
        href={ `/vod/${match.match.id}/1` }
        onclick={ this.link }>
        <div class="teams">
          <div
            class="small-games-toggle"
            onclick={ this.showGames }
            role="button"
            aria-label={
              match.match.teams[0].code
              + ' '
              + locale.translate('match.vs')
              + ' '
              + match.match.teams[1].code
            }
          />
          { this.renderTeam(match.match.teams[0]) }
          <span class="vs">{ locale.translate('match.vs') }</span>
          { this.renderTeam(match.match.teams[1]) }
        </div>
        <div class="game-selector">
          <VodsGameSelector
            match={ match.match }
            games={ match.games }
            options={ { tooltipAlignment: 'right' } }
            tournament={ this.props.tournament }
          />
        </div>
        { isTiebreaker && this.renderSpoilerCover() }
      </a>
    )

    // add tooltip if no VOD yet available
    const message = locale.translate('message.vodNotAvailable')

    if (!match.games[0].vods.length) {
      informTooltip.addTooltip(matchElement, message, 'center')
    }

    return matchElement
  }

  render () {
    const match = this.props.match

    // used to determine if the games drawer is open on small devices
    const showGamesClass = this.props.showMatchGames && 'show-games'

    // used to show and hide the tiebreaker overlay
    const isTiebreaker = match.match.type === 'tiebreaker'
    const tiebreakerClass = isTiebreaker && 'tiebreaker'

    // used to show and hide the spoiler cover
    const hideSpoilersClass = this.state.hideSpoilers && 'hide-spoilers'

    const matchClasses = util.classNames(
      this.constructor.name,
      'match',
      showGamesClass,
      tiebreakerClass,
      hideSpoilersClass
    )

    return (
      <div class={ matchClasses }>{ this.renderMatch(match, isTiebreaker) }</div>
    )
  }
}

export default VodsMatch
