import { $, Logger, store, erasHeartbeater, router, render } from 'lib'
import locale from 'Common/Locale/Locale'
import util from 'Common/Util/Util'
import videoPlayerUtil from 'Component/Video/Player/Util/VideoPlayerUtil'
import rewardsOpting from 'Common/Service/Rewards/Opting/RewardsOpting'
import watchUtil from '../../../../Container/Watch/Util/WatchUtil'
import JumpToButton from 'Component/Video/Controls/JumpToButton'

const scriptUrl = 'https://player.twitch.tv/js/embed/v1.js'

// TODO: Use a REAL timestamp, game specific (web api or somewhere?)
let updateTimestampId = 0

let handleMultiGameVodBoundaryId = 0

// Updates how often we get currentTime from the player
const updateTimestampInterval = 0.5 * util.times.SECOND
// Updates how often we check the VOD time to see if its past a game boundary due to delayed timestamps
const handleMultiGameVodBoundaryInterval = util.times.SECOND
// We jump a little further than the actual start to prevent being off
const gameStartPush = 10 * util.times.SECOND
// When trying to seek, force waiting for one second (to allow player to play)
const delayForSeek = 1 * util.times.SECOND

// Twitch timestamps use a '00h00m00s' format
const twitchTimeIntervals = [
  {
    value: util.times.HOURS,
    label: 'h'
  },
  {
    value: util.times.MINUTES,
    label: 'm'
  },
  {
    value: util.times.SECOND,
    label: 's'
  }
]

/**
 * This is a regular class, not a component
 * API: https://dev.twitch.tv/docs/embed
 */
class VideoPlayerTwitch {
  constructor () {
    this.log = new Logger(this.constructor.name)
    this.state = videoPlayerUtil.states.unstarted
    this.startTimestamp = 0
    this.offset = 0
    this.firstFrameTime = 0
    this.addListeners()
  }

  addListeners () {
    // this happens once, don't need to be removed
    store.onChange('watch.offset', (offset) => {
      this.offset = offset
    })

    store.onChange('watch.firstFrameTime', (firstFrameTime) => {
      this.firstFrameTime = firstFrameTime
    })

    if (this.streamType === watchUtil.streamTypes.vod) {
      // this happens once, don't need to be removed
      store.onChange('watch.initialTimestamp', (initialTimestamp) => {
        this.startTimestamp = Date.parse(initialTimestamp)
      })
    }
  }

  getState () {
    return this.state
  }

  setState (state) {
    this.state = state
    this.log.info('State changed to', videoPlayerUtil.getStateString(state))
    store.set('videoPlayer.state', this.state)

    this.clearUpdateTimestamp()
    this.clearHandleMultiGameVodBoundary()

    if (this.state === videoPlayerUtil.states.playing) {
      this.updateTimestamp()
      this.setUpdateTimestampInterval()
      this.setMultiGameVodBoundaryInterval()
    }

    if (this.state === videoPlayerUtil.states.ended) {
      $('#video-player').append(this.renderJumpToVods())
    }
  }

  renderJumpToVods () {
    return render(
      <JumpToButton
        onJump={ () => this.goToVods() }
        text={ locale.translate('video.jumpToGameButton.vods') }
        hideClose
        defaultCompleteJump
      />
    )
  }

  goToVods () {
    this.removePlayer()
    window.location.pathname = `/${locale.get()}/vods/${this.leagueSlug}`
  }

  toTwitchTimeFormat (milliseconds) {
    let time = milliseconds

    let timeString = ''

    for (let i = 0; i < twitchTimeIntervals.length; i++) {
      const currentInterval = Math.floor(time / twitchTimeIntervals[i].value)

      time -= currentInterval * twitchTimeIntervals[i].value
      timeString += currentInterval + twitchTimeIntervals[i].label
    }

    return timeString
  }

  updateTimestamp () {
    const currentTime = this.getVideoCurrentTime()

    if (this.streamType === watchUtil.streamTypes.live) {
      store.set('videoPlayer.timestamp', currentTime)
    }
    else {
      // vod case - use firstFrameTime
      const timestamp = util.toMilliseconds(this.firstFrameTime) + currentTime

      store.set('videoPlayer.timestamp', timestamp)
    }
  }

  handleMultiGameVodBoundary () {
    const currentTime = this.getVideoCurrentTime()

    // live and no timeline vods can be ignored
    if (
      this.streamType === watchUtil.streamTypes.live
      || !this.timeline
      || !this.timelineEvent
    ) {
      return
    }

    this.timeline.forEach((entity) => {
      if (
        entity.startMillis <= currentTime
        && currentTime <= entity.endMillis
      ) {
        if (
          this.timelineEvent.matchId === entity.matchId
          && this.timelineEvent.gameNumber === entity.gameNumber
          && this.timelineEvent.gameId === entity.gameId
        ) {
          return
        }

        // if this is a different game we're in, set initial timestamp to undefined (preventing spoilers)
        store.set('watch.initialTimestamp', undefined)

        // Use redirect to not trigger miniplayer popups
        router.redirect(
          `/vod/${entity.matchId}/${entity.gameNumber}/${this.uuid}`
        )
      }
    })
  }

  setMultiGameVodBoundaryInterval () {
    handleMultiGameVodBoundaryId = window.setInterval(() => {
      this.handleMultiGameVodBoundary()
    }, handleMultiGameVodBoundaryInterval)
  }

  clearHandleMultiGameVodBoundary () {
    window.clearInterval(handleMultiGameVodBoundaryId)
  }

  setUpdateTimestampInterval () {
    updateTimestampId = window.setInterval(() => {
      this.updateTimestamp()
    }, updateTimestampInterval)
  }

  clearUpdateTimestamp () {
    window.clearInterval(updateTimestampId)
  }

  play () {
    this.player.play()
  }

  pause () {
    this.player.pause()
  }

  remove () {
    this.log.debug('Removing player')
    erasHeartbeater.twitchHeartbeater.detach(this.player)
    this.player = undefined
    this.clearUpdateTimestamp()
  }

  configureHeartbeater () {
    const heartbeaterConfig = rewardsOpting.getHeartbeaterConfig()

    erasHeartbeater.twitchHeartbeater.setConfig({
      ...heartbeaterConfig,
      streamId: this.uuid,
      streamType: this.streamType,
      tournamentId: this.tournamentId,
      onHeartbeatCallback: () =>
        videoPlayerUtil.trackHeartbeat(
          'twitch',
          this.streamType,
          this.tournamentId,
          this.uuid
        )
    })

    erasHeartbeater.twitchHeartbeater.attach(this.player)
  }

  loadById (
    tournamentId,
    uuid,
    streamType,
    startMillis,
    endMillis,
    timelineEvent,
    timeline,
    leagueSlug,
    removePlayer
  ) {
    const isSameVideo = this.uuid === uuid
    const isSameTimelineEvent = this.timelineEvent === timelineEvent

    this.tournamentId = tournamentId
    this.uuid = uuid
    this.streamType = streamType
    this.leagueSlug = leagueSlug
    this.startMillis = startMillis || 0
    this.endMillis = endMillis
    this.timelineEvent = timelineEvent
    this.timeline = timeline
    this.removePlayer = removePlayer
    this.configureHeartbeater()

    if (streamType === watchUtil.streamTypes.vod) {
      const startOffset = this.startMillis / util.times.SECONDS

      // If this is the same video but a new event, we'll maybe want to skip to start
      if (isSameVideo && !isSameTimelineEvent) {
        const time = this.getVideoCurrentTime()

        // If we come from a time outside our range, we should skip (when user clicks game selector)
        // and cancel how we do multigame boundary (the next play event will reset these)
        // and set timestamps to undefined to prevent spoilers
        if (time <= this.startMillis || time >= this.endMillis) {
          // TODO: Remove this once the Twitch player is fixed
          // Twitch player does not seek if the video is not playing, so we force it to play before seeking
          if (this.state !== videoPlayerUtil.states.playing) {
            this.player.play()
            setTimeout(() => this.player.seek(startOffset), delayForSeek)
          }
          else {
            this.player.seek(startOffset)
          }
          this.clearHandleMultiGameVodBoundary()
          store.set('videoPlayer.timestamp', undefined)
          store.set('watch.initialTimestamp', undefined)
        }
      }

      if (!isSameVideo) {
        this.player.setVideo(this.uuid)
        setTimeout(() => this.player.seek(startOffset), util.times.SECOND)
      }
    }
    else {
      this.player.setChannel(this.uuid)
    }
  }

  addToPlaylist () {
    // for compatibility between all player providers
  }

  getVideoCurrentTime () {
    if (this.streamType === watchUtil.streamTypes.live) {
      return Date.now() + this.offset
    }
    else if (
      this.player
      && typeof this.player.getCurrentTime === 'function'
      && this.streamType === watchUtil.streamTypes.vod
    ) {
      return Math.floor(this.player.getCurrentTime() * 1000)
    }
    else {
      return 0
    }
  }

  getPlayerInstance () {
    if (this.streamType === watchUtil.streamTypes.vod) {
      return new window.Twitch.Player('video-player-twitch', {
        video: this.uuid,
        height: '100%',
        width: '100%',
        theme: 'dark',
        parent: [window.location.hostname],
        time: this.toTwitchTimeFormat(this.startMillis + gameStartPush)
      })
    }
    else {
      return new window.Twitch.Player('video-player-twitch', {
        channel: this.uuid,
        height: '100%',
        width: '100%',
        theme: 'dark',
        parent: [window.location.hostname]
      })
    }
  }

  setup (
    tournamentId,
    uuid,
    streamType,
    startMillis,
    endMillis,
    timelineEvent,
    timeline,
    leagueSlug,
    removePlayer
  ) {
    this.tournamentId = tournamentId
    this.uuid = uuid
    this.streamType = streamType
    this.startMillis = startMillis || 0
    this.endMillis = endMillis
    this.timelineEvent = timelineEvent
    this.timeline = timeline
    this.leagueSlug = leagueSlug
    this.removePlayer = removePlayer

    window.require([scriptUrl], () => {
      this.player = this.getPlayerInstance()

      this.player.addEventListener(window.Twitch.Player.READY, () => {
        this.log.debug('Player is ready')

        this.addPlayerStateListeners()
        this.configureHeartbeater()
      })
    })
  }

  addPlayerStateListeners () {
    this.player.addEventListener(window.Twitch.Player.PLAY, () => {
      store.set('videoPlayer.dismissJumpButton', undefined)

      if (
        util.isTouchDevice()
        && this.streamType === watchUtil.streamTypes.vod
        && this.state === videoPlayerUtil.states.unstarted
      ) {
        this.player.seek(this.startMillis / util.times.SECONDS)
      }

      this.setState(videoPlayerUtil.states.playing)
    })

    this.player.addEventListener(window.Twitch.Player.PAUSE, () =>
      this.setState(videoPlayerUtil.states.paused)
    )

    this.player.addEventListener(window.Twitch.Player.ENDED, () =>
      this.setState(videoPlayerUtil.states.ended)
    )
  }

  render () {
    return <div id="video-player-twitch"/>
  }
}

export default new VideoPlayerTwitch()
