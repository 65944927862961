import analytics from 'Common/Service/Analytics/Analytics'

class VideoPlayerUtil {
  get states () {
    return {
      // using youtube states as the default. Other players will be translated
      unstarted: -1,
      ended: 0,
      playing: 1,
      paused: 2,
      buffering: 3,
      queued: 5
    }
  }

  getStateString (state) {
    return Object.keys(this.states).find((key) => this.states[key] === state)
  }

  trackHeartbeat (player, streamType, tournamentId, streamId) {
    analytics.trackEvent('heartbeat', {
      player: player,
      streamType: streamType,
      tournamentId: tournamentId,
      streamId: streamId
    })
  }
}

export default new VideoPlayerUtil()
