import { Component, router } from 'lib'
import Image from 'Component/Asset/Image/Image'
import Icon from 'Component/Asset/Icon/Icon'
import locale from 'Common/Locale/Locale'

class HomeVodsMatch extends Component {
  constructor () {
    super()
    this.addListeners()
  }

  addListeners () {
    this.playVod = (matchId) => {
      const url = 'vod/' + matchId + '/1'

      router.go(url)
    }
  }

  renderLeague (league) {
    return <div class="league">{ league.name }</div>
  }

  renderMatchTitle (matchInfo) {
    const teams = matchInfo.match.teams
    const title
      = `${teams[0].code} ${locale.translate('match.vs').toLowerCase()} `
      + `${teams[1].code} ${Icon.unicode('dash')} ${matchInfo.blockName}`

    return <div class="match-title">{ title }</div>
  }

  renderImageWrapper (backgroundImage) {
    const sizes = { large: 100, medium: 300, small: 100 }

    return (
      <div class="image-wrapper">
        <div
          class="team-image"
          style={ Image.background(backgroundImage, sizes) }
        />
      </div>
    )
  }

  renderStylizedBackground (teams) {
    const teamOneBackground
      = teams[0].backgroundImage || '/home/generic-team-plate-one.png'
    const teamTwoBackground
      = teams[1].backgroundImage || '/home/generic-team-plate-two.png'

    return (
      <div class="background">
        { this.renderImageWrapper(teamOneBackground) }
        { this.renderImageWrapper(teamTwoBackground) }
      </div>
    )
  }

  renderTeam (team) {
    return (
      <div class="team">
        <Image class="image" src={ team.image } size="70" alt={ team.code }/>
      </div>
    )
  }

  renderThumbnail (teams) {
    return (
      <div class="thumbnail">
        { this.renderStylizedBackground(teams) }
        <div class="teams">
          { this.renderTeam(teams[0]) }
          <div class="vs">{ locale.translate('match.vs') }</div>
          { this.renderTeam(teams[1]) }
        </div>
      </div>
    )
  }

  render () {
    const matchInfo = this.props.matchInfo

    return (
      <div
        class={ this.constructor.name }
        onclick={ () => this.playVod(matchInfo.match.id) }>
        { this.renderThumbnail(matchInfo.match.teams) }
        <div class="description">
          { this.renderLeague(matchInfo.league) }
          { this.renderMatchTitle(matchInfo) }
        </div>
      </div>
    )
  }
}

export default HomeVodsMatch
