import { Component, Logger } from 'lib'
import device from 'Common/Device/Device'
import locale from 'Common/Locale/Locale'
import HomeVodsMatch from 'Component/Home/Vods/Match/HomeVodsMatch'
import Icon from 'Component/Asset/Icon/Icon'

const initialVods = {
  small: 6,
  medium: 4,
  large: 12
}

const numVodsToLoad = 4

class HomeVods extends Component {
  constructor (props) {
    super(props)
    this.log = new Logger(this.constructor.name)
    this.setState({ numVodsToShow: initialVods[device.getSize()] })
    this.addListeners()
  }

  addListeners () {
    this.loadMoreVods = () => {
      let numVodsToShow = this.state.numVodsToShow
      const maxVods = this.props.matches.length
      // If we already are showing the maximum number of VODs, then
      // there is nothing additional to render

      if (numVodsToShow === maxVods) {
        return
      }

      // Every time that the load more button is pressed, it should load an
      // additional preset number of VODs. Once all VODs have been loaded,
      // the load more button should no longer render.
      numVodsToShow += numVodsToLoad
      numVodsToShow >= maxVods
        ? this.setState({ numVodsToShow: maxVods })
        : this.setState({ numVodsToShow })
    }
  }

  renderMatches (matches) {
    const visibleMatches = matches.slice(0, this.state.numVodsToShow)

    return visibleMatches.map((match) => this.renderMatch(match))
  }

  renderMatch (match) {
    return (
      <div class="match">
        <HomeVodsMatch matchInfo={ match }/>
      </div>
    )
  }

  renderLoadMore () {
    if (this.state.numVodsToShow >= this.props.matches.length) {
      return
    }

    return (
      <div class="load-more" role="button" onclick={ this.loadMoreVods }>
        { locale.translate('home.vods.loadMore') }
        <Icon name="arrow" direction="down"/>
      </div>
    )
  }

  render () {
    if (!this.props.matches) {
      return
    }

    return (
      <div class={ this.constructor.name }>
        <div class="title">{ locale.translate('home.vods.title') }</div>
        <div class="vods-list">{ this.renderMatches(this.props.matches) }</div>
        { this.renderLoadMore() }
      </div>
    )
  }
}

export default HomeVods
