import { Component, store, $ } from 'lib'
import Image from 'Component/Asset/Image/Image'
import VodsTournaments from 'Component/Vods/Tournaments/VodsTournaments'
import Icon from 'Component/Asset/Icon/Icon'
import util from 'Common/Util/Util'

class VodsTopNav extends Component {
  componentWillMount () {
    this.addListeners()
  }

  addListeners () {
    this.toggleVodsFilter = () => {
      store.set('vods.toggleFilter', !store.get('vods.toggleFilter'))
    }

    $(document).on(
      'click',
      this.clickListener = (event) => {
        if (
          store.get('vods.toggleFilter')
          && !$(event.target).closest('.league-selector').length
        ) {
          store.set('vods.toggleFilter', false)
        }
      }
    )
  }

  componentWillUnmount () {
    $(document).off('click', this.clickListener)
  }

  renderLeague (league) {
    return (
      <a class="info">
        <Image class="image" src={ league.image } size="60"/>
        <div class="label">
          <div class="name">{ league.name }</div>
          <div class="region">{ league.region }</div>
        </div>
      </a>
    )
  }

  render () {
    const { selectedTournament, tournaments, selectedLeague } = this.props
    const componentClasses = util.classNames(
      this.constructor.name,
      this.props.filterActiveClass
    )

    return (
      <div class={ componentClasses }>
        <div
          class="league-selector"
          onclick={ this.toggleVodsFilter }
          role="button">
          <div class="league">
            { this.renderLeague(selectedLeague) }
            <Icon
              name="arrow"
              direction={ this.props.filterActiveClass ? 'up' : 'down' }
            />
          </div>
        </div>
        <VodsTournaments
          selectedTournament={ selectedTournament }
          tournaments={ tournaments }
          selectedLeague={ selectedLeague }
          tournamentChangeHandler={ this.props.tournamentChangeHandler }
        />
      </div>
    )
  }
}

export default VodsTopNav
