import { Component, Logger, store, $ } from 'lib'
import util from 'Common/Util/Util'
import Icon from 'Component/Asset/Icon/Icon'

class JumpToButton extends Component {
  constructor () {
    super()
    this.log = new Logger(this.constructor.name)
    this.dismissTimeoutId = window.setTimeout(
      () =>
        this.props.defaultCompleteJump
          ? this.completeJumpTo()
          : this.dismissJumpTo(),
      15 * util.times.SECONDS
    )
    this.addListeners()
  }

  addListeners () {
    store.onChange(
      'videoPlayer.dismissJumpButton',
      this.dismissListener = () => {
        this.dismissJumpTo()
      }
    )

    $(document).on(
      'router.navigation.go',
      this.onNavigation = () => {
        // if navigating to another VOD, the button will be re-rendered
        this.dismissJumpTo()
      }
    )
  }

  completeJumpTo () {
    this.props.onJump()
    this.dismissJumpTo()
  }

  dismissJumpTo () {
    window.clearTimeout(this.dismissTimeoutId)
    $(document).off('router.navigation.go', this.onNavigation)
    store.removeListener('videoPlayer.dismissJumpButton', this.dismissListener)
    this.setState({ dismissed: true })
  }

  render () {
    if (this.state.dismissed) {
      return
    }

    return (
      <div class={ this.constructor.name }>
        <div class="text" onClick={ () => this.completeJumpTo() } role="button">
          { this.props.text }
        </div>
        { !this.props.hideClose && (
          <div class="close" onClick={ () => this.dismissJumpTo() } role="button">
            <Icon name="bubbleClose"/>
          </div>
        ) }
      </div>
    )
  }
}

export default JumpToButton
