import { Component } from 'lib'
import InformLoading from 'Component/Inform/Loading/InformLoading'
import InformBubble from 'Component/Inform/Bubble/InformBubble'
import Icon from 'Component/Asset/Icon/Icon'
import locale from 'Common/Locale/Locale'
import util from 'Common/Util/Util'
import VodsMatch from 'Component/Vods/Match/VodsMatch'

class VodsList extends Component {
  constructor () {
    super()
    this.addEventListeners()
  }

  addEventListeners () {
    this.showMatchGamesHandler = (matchId) => {
      this.setState({ selectedMatchId: matchId })
    }
  }

  renderDate (date) {
    const formats = locale.translate(Date.parse(date))

    return (
      <span class="date">
        { util.toUpperFirst(formats.relativeWeekday) }, { formats.dayAndMonth }
      </span>
    )
  }

  renderTiebreakersLabel () {
    return (
      <span class="name">
        { Icon.unicode('dash') } { locale.translate('league.round.tiebreakers') }
      </span>
    )
  }

  renderRoundTitle (match) {
    return (
      <div class="round">
        <h2 class="title">
          <span class="type">{ match.blockName }</span>
          { Icon.unicode('dash') }
          { this.renderDate(match.startTime) }
          { match.match.type === 'tiebreaker' && this.renderTiebreakersLabel() }
        </h2>
      </div>
    )
  }

  renderMatches (matches) {
    let prevItem = {}
    const elements = []

    // We are getting returned a list of matches (newest to oldest) and need
    // to reorder them so that a user sees the most recent day of matches
    // at the top of the vods page, but each day is ordered from first game
    // played to last game played (from oldest to newest)
    let insertionIndex = 0
    // Filter matches to only show completed matches

    matches
      .filter(
        (match) =>
          Date.parse(match.startTime) < Date.now()
          || match.state === 'completed'
      )
      .forEach((match) => {
        const matchTimestamp = Date.parse(match.startTime)
        const prevTimestamp = Date.parse(prevItem.startTime)

        if (
          !util.isSameDay(matchTimestamp, prevTimestamp)
          || match.match.type !== prevItem.match.type
        ) {
          elements.push(this.renderRoundTitle(match))
          insertionIndex = elements.length
        }
        prevItem = match

        const vodsMatchElement = (
          <VodsMatch
            match={ match }
            showMatchGamesHandler={ this.showMatchGamesHandler }
            showMatchGames={ this.state.selectedMatchId === match.match.id }
            tournament={ this.props.selectedTournament }
          />
        )

        elements.splice(insertionIndex, 0, vodsMatchElement)
      })

    return elements
  }

  renderErrorOrLoading (error) {
    return (
      <main class={ this.constructor.name }>
        { error ? (
          <InformBubble theme="error" icon="error">
            { error.message }
          </InformBubble>
        )
          : <InformLoading/>
        }
      </main>
    )
  }

  renderCheckBack (unstartedMatch) {
    let startDate

    if (unstartedMatch && unstartedMatch.length !== 0) {
      const matchStartDate = util.toUTC(
        util.toMilliseconds(unstartedMatch[0].startTime)
      )

      startDate = locale.date(matchStartDate).dayAndMonth
    }
    else {
      const tournamentStartDate = util.toUTC(
        util.toMilliseconds(this.props.selectedTournament.startDate)
      )

      startDate = locale.date(tournamentStartDate).dayAndMonth
    }
    return (
      <div class={ this.constructor.name }>
        <div class="round coming-soon">
          <h2 class="title">
            <span class="type">{ locale.translate('vods.comingSoon') }</span>
          </h2>
        </div>
        <div class="round no-vods">
          <h2 class="title">
            <span>{ locale.translate('vods.noVods', { startDate }) }</span>
          </h2>
        </div>
      </div>
    )
  }

  renderNoVodsAvailable () {
    return (
      <div class={ this.constructor.name }>
        <div class="round no-vods-available">
          <h2 class="title">
            <span class="type">{ locale.translate('vods.sorry') }</span>
          </h2>
        </div>
        <div class="round no-vods">
          <h2 class="title">
            <span>{ locale.translate('vods.noVodsForTournament') }</span>
          </h2>
        </div>
      </div>
    )
  }

  render () {
    if (!this.props.matches || this.state.error) {
      return this.renderErrorOrLoading(this.state.error)
    }

    const startTimeMillis = util.toUTC(
      util.toMilliseconds(this.props.selectedTournament.startDate)
    )
    const endTimeMillis = util.toUTC(
      util.toMilliseconds(this.props.selectedTournament.endDate)
    )

    if (!this.props.matches.length) {
      // If the tournament has ended or there is no available data on the next unstarted match and we're past the tournament start date
      // show the no vods available message
      if (
        endTimeMillis < Date.now()
        || this.props.nextUnstartedMatch.length === 0
          && startTimeMillis < Date.now()
      ) {
        return this.renderNoVodsAvailable()
      }
      // If we're past the unstarted match start time show the no vods available message
      else if (
        this.props.nextUnstartedMatch.length !== 0
        && util.toUTC(
          util.toMilliseconds(this.props.nextUnstartedMatch[0].startTime)
        ) < Date.now()
      ) {
        return this.renderNoVodsAvailable()
      }
      // Else use the tournament start date or next unstarted match time to display the check back soon message
      else {
        return this.renderCheckBack(this.props.nextUnstartedMatch)
      }
    }

    return (
      <div class={ this.constructor.name }>
        { this.renderMatches(this.props.matches) }
      </div>
    )
  }
}

export default VodsList
