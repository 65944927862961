import { $ } from 'lib'
import Icon from 'Component/Asset/Icon/Icon'
import util from 'Common/Util/Util'
import device from 'Common/Device/Device'

let $controls, $playPauseControl
const touchHideControlsAfter = 2000 // ms

/*
 * This is a regular class used by the main VideoPlayer component
 * to display and control the Mini Player
 */
class VideoPlayerMini {
  constructor () {
    this.player = null
    this.provider = null
  }

  setup (player, provider) {
    this.player = player
    this.provider = provider
    $controls = $('#video-player-mini-controls')
    $playPauseControl = $controls.find('#play-pause')

    this.addListeners() // needs to be called on setup(), after $controls exist
  }

  addListeners () {
    device.addSwipeListener($controls, (direction) => {
      if (direction.leftToRight || direction.rightToLeft) {
        this.close(true) // force close even if controls are not showing
      }
    })

    this.close = (force) => {
      // no mousehover for mobile. When clicking the miniplayer,
      // should not trigger the action if controls are not showing
      if (!force && this.areControlsHidden()) return

      if (this.player.isSameRoute()) {
        this.player.attach()
        this.player.setShouldFloat(false)
      }
      else {
        this.player.remove()
        device.removeSwipeListener($controls)
      }
    }

    this.restore = () => {
      if (this.areControlsHidden()) return

      if (this.player.isSameRoute()) {
        window.scrollTo(0, 0)
      }
      else {
        this.player.restore()
      }
    }

    this.playPause = () => {
      if (this.areControlsHidden()) return

      if (this.player.isPaused()) {
        this.player.play()
      }
      else {
        this.player.pause()
      }
      this.reset()
    }

    this.mouseOver = () => {
      if (!this.player.isPaused()) {
        $controls.css('opacity', 1)
      }
    }

    this.mouseOut = () => {
      if (!this.player.isPaused()) {
        $controls.css('opacity', 0)
      }
    }

    let timeoutId = 0

    this.showControls = () => {
      if (util.isTouchDevice()) {
        window.clearTimeout(timeoutId)
        $controls.css('opacity', 1)
        timeoutId = window.setTimeout(() => {
          $controls.css('opacity', 0)
        }, touchHideControlsAfter)
      }
    }
  }

  areControlsHidden () {
    return Number($controls.css('opacity')) === 0
  }

  updatePlayPauseIcon (prop) {
    const icon = $playPauseControl.find('.icon').get(0)

    if (icon && icon._component) {
      icon._component.props = { name: prop }
      icon._component.forceUpdate()
    }
  }

  reset () {
    if (this.player.isPaused()) {
      this.updatePlayPauseIcon('play')
      $playPauseControl.removeClass('pause').addClass('play')
      $controls
        .removeClass('playing')
        .addClass('paused')
        .css('opacity', 1)
    }
    else {
      this.updatePlayPauseIcon('pause')
      $playPauseControl.removeClass('play').addClass('pause')
      $controls
        .removeClass('paused')
        .addClass('playing')
        .css('opacity', 0)
    }
  }

  show () {
    $controls.css('display', 'block')
  }

  hide () {
    $controls.css('display', 'none')
  }

  render () {
    return (
      <div
        role="button"
        id="video-player-mini-controls"
        class={ this.provider }
        onclick={ () => this.showControls() }
        onmouseover={ () => this.mouseOver() }
        onmouseout={ () => this.mouseOut() }>
        <div
          class="control restore"
          onclick={ () => this.restore() }
          role="button">
          <Icon name="restore"/>
        </div>
        <div
          role="button"
          id="play-pause"
          class="control pause"
          onclick={ () => this.playPause() }>
          <Icon name="pause"/>
        </div>
        <div class="control close" onclick={ () => this.close() } role="button">
          <Icon name="close"/>
        </div>
      </div>
    )
  }
}

export default new VideoPlayerMini()
