import { Logger } from 'lib'
import riotBar from 'Common/Service/RiotBar/RiotBar'
import config from 'Common/Config/Config'
import serviceUtil from 'Common/Service/Util/ServiceUtil'

const hosts = {
  local: '/mock-service/eras-watchstats/v1',
  dev: 'https://dev-raptor.rewards.lolesports.com/v1',
  test: 'https://qa-raptor.rewards.lolesports.com/v1',
  stage: 'https://stage-raptor.rewards.lolesports.com/v1',
  prod: 'https://raptor.rewards.lolesports.com/v1'
}

const env = window.sessionStorage.getItem('rewards-api-env') || config.env
const baseUrl = hosts[env]

class RewardsWatchStats {
  constructor () {
    this.log = new Logger(this.constructor.name)
  }

  fetchWatchStats () {
    if (!riotBar.isLoggedIn()) {
      return Promise.resolve({ loggedOut: true })
    }

    const url = baseUrl + '/history/stats?sport=lol'

    return serviceUtil
      .fetch(url, {
        credentials: 'include',
        headers: {
          Authorization: 'Cookie __Secure-access_token'
        }
      })
      .then((json) =>
        json.reduce((watchStats, watchStat) => {
          watchStats[watchStat.statName] = watchStat.statValue
          return watchStats
        }, {})
      )
  }
}

export default new RewardsWatchStats()
