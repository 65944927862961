import { Component, store } from 'lib'
import Image from 'Component/Asset/Image/Image'
import locale from 'Common/Locale/Locale'
import util from 'Common/Util/Util'

class VodsFilter extends Component {
  constructor () {
    super()
    this.state.expanded = false
    this.addListeners()
  }

  addListeners () {
    this.selectLeagueListener = (leagueSlug) => {
      store.set('vods.toggleFilter', false)
      this.props.leagueChangeHandler
        && this.props.leagueChangeHandler(leagueSlug)
    }
  }

  renderLeague (league) {
    const selected = this.props.selectedLeague.id === league.id

    return (
      <li class={ util.classNames('league', selected && 'selected') }>
        <button
          class="info button"
          onclick={ () => this.selectLeagueListener(league.slug) }>
          <Image class="image" src={ league.image } size="60"/>
          <div class="label">
            <div class="name">{ league.name }</div>
            <div class="region">{ league.region }</div>
          </div>
        </button>
      </li>
    )
  }

  renderLeagues (leagues) {
    return leagues.map((league) => this.renderLeague(league))
  }

  render () {
    return (
      <div class={ this.constructor.name }>
        <h2 class="title">{ locale.translate('watch.vods.regionsAndEvents') }</h2>
        <ul class="leagues">{ this.renderLeagues(this.props.leagues) }</ul>
      </div>
    )
  }
}

export default VodsFilter
