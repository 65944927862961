import { Logger, store, $, erasHeartbeater } from 'lib'
import videoPlayerUtil from 'Component/Video/Player/Util/VideoPlayerUtil'
import util from 'Common/Util/Util'
import rewardsOpting from 'Common/Service/Rewards/Opting/RewardsOpting'
import watchUtil from 'Container/Watch/Util/WatchUtil'

const scriptUrl = 'https://static.afreecatv.com/asset/app/embed/embed.min.js'

let updateTimestampId = 0

// Updates how often we get currentTime from the player
const updateTimestampInterval = 0.5 * util.times.SECOND
/**
 * This is a regular class. It is one of the specialized video provider
 * classes used by the main VideoPlayer component to manage specific API code
 * for each provider, in this case, Afreeca
 */

class VideoPlayerAfreeca {
  constructor () {
    this.log = new Logger(this.constructor.name)
    this.state = videoPlayerUtil.states.unstarted
    this.offset = 0
    this.firstFrameTime = 0
    this.startTimestamp = 0
    this.addListeners()
  }

  addListeners () {
    // this happens once, don't need to be removed
    store.onChange('watch.offset', (offset) => {
      this.offset = offset
    })

    store.onChange('watch.firstFrameTime', (firstFrameTime) => {
      this.firstFrameTime = firstFrameTime
    })

    if (this.streamType === watchUtil.streamTypes.vod) {
      // this happens once, don't need to be removed
      store.onChange('watch.initialTimestamp', (initialTimestamp) => {
        this.startTimestamp = Date.parse(initialTimestamp)
      })
    }
  }

  getState () {
    return this.state
  }

  setState (state) {
    this.state = state
    this.log.info('State changed to', videoPlayerUtil.getStateString(state))
    store.set('videoPlayer.state', this.state)
    this.clearUpdateTimestamp()
  }

  play () {
    this.player.play()
  }

  configureHeartbeater () {
    const heartbeaterConfig = rewardsOpting.getHeartbeaterConfig()

    erasHeartbeater.afreecaTVHeartbeater.setConfig({
      ...heartbeaterConfig,
      streamId: this.uuid,
      streamType: this.streamType,
      tournamentId: this.tournamentId,
      source: 'afreecatv',
      onHeartbeatCallback: () =>
        videoPlayerUtil.trackHeartbeat(
          'afreecatv',
          this.streamType,
          this.tournamentId,
          this.uuid
        )
    })

    erasHeartbeater.afreecaTVHeartbeater.attach(this.player)
  }

  pause () {
    this.player.pause()
  }

  updateTimestamp () {
    const currentTime = this.getVideoCurrentTime()

    if (this.streamType === watchUtil.streamTypes.live) {
      store.set('videoPlayer.timestamp', currentTime)
    }
    else {
      // vod case - use firstFrameTime
      const timestamp = util.toMilliseconds(this.firstFrameTime) + currentTime

      store.set('videoPlayer.timestamp', timestamp)
    }
  }

  getVideoCurrentTime () {
    if (this.streamType === watchUtil.streamTypes.live) {
      return Date.now() + this.offset
    }
    else if (
      this.player
      && typeof this.player.getCurrentTime === 'function'
      && this.streamType === watchUtil.streamTypes.vod
    ) {
      return this.player.getCurrentTime()
    }
    else {
      return 0
    }
  }

  loadById (tournamentId, uuid, streamType, startMillis, endMillis) {
    this.tournamentId = tournamentId
    this.uuid = uuid
    this.streamType = streamType
    this.startMillis = startMillis
    this.endMillis = endMillis
    this.log.debug('Loading video with id', this.uuid)
    this.remove()
    store.set('videoPlayer.timestamp', undefined)
    store.set('watch.initialTimestamp', undefined)
    this.createPlayer()
  }

  addToPlaylist () {
    // for compatibility between all player providers
  }

  setup (tournamentId, uuid, streamType, startMillis) {
    this.tournamentId = tournamentId
    this.uuid = uuid
    this.streamType = streamType
    this.startMillis = startMillis
    this.createPlayer()
    this.configureHeartbeater()
  }

  createPlayer () {
    // don't load afreecatv scripts twice
    if (
      $('#video-player-afreeca-script').exists()
      && window.onAFIframeAPIReady
      && window.AF
    ) {
      return window.onAFIframeAPIReady()
    }

    const script = document.createElement('script')

    script.id = 'video-player-afreeca-script'
    script.src = scriptUrl
    $('head').append(script)

    window.onAFIframeAPIReady = () => {
      // TODO: See if we can find a better way to support autoplay for Afreeca
      // To support autoplay for Afreeca with our current page structure for navigating between VODS (which doesn't reload the page between VODs)
      // and since the player API does not support a destroy method,
      // we need to create a new unique id'd element for the player to be loaded into each time
      const playerId = `video-player-afreeca-vod-${Date.now()}`

      $('#video-player').append(`<div id="${playerId}"></div>`)

      this.player = new window.AF.Player(playerId, {
        height: '100%',
        width: '100%',
        autoPlay: true,
        isAdShow: false,
        showChat: false,
        mutePlay: false
      })

      this.configureHeartbeater()
      if (this.streamType === watchUtil.streamTypes.vod) {
        // Only use start offeset to load the video if one is provided otherwise
        // use the start time set by the VOD uploader)
        const startOffset
          = this.startMillis && this.startMillis / util.times.SECOND

        if (startOffset) {
          this.player.loadVideo({
            videoId: this.uuid,
            startVideoSeconds: startOffset
          })
        }
        else {
          this.player.loadVideo({ videoId: this.uuid })
        }
      }
      else {
        this.player.loadVideo({ channelId: this.uuid })
      }
    }

    // Monkeypatch the window.onAFPlayerStateChange since it is in the Afreeca Heartbeater
    // in @riotgames/eras-heartbeater. Overriding the function here will break the heartbeaters,
    // so to keep heartbeaters working and to allow the miniplayer to work, this is the quickest solution
    let _onAFPlayerStateChange = () => {}

    if (window.onAFPlayerStateChange) {
      _onAFPlayerStateChange = window.onAFPlayerStateChange
    }
    window.onAFPlayerStateChange = (event) => {
      switch (event.data) {
        case window.AF.PlayerState.PLAYING:
          this.setState(videoPlayerUtil.states.playing)
          this.updateTimestamp()
          this.setUpdateTimestampInterval()
          break
        case window.AF.PlayerState.PAUSED:
          this.setState(videoPlayerUtil.states.paused)
          this.clearUpdateTimestamp()
          break
        case window.AF.PlayerState.ENDED:
          this.setState(videoPlayerUtil.states.ended)
          this.clearUpdateTimestamp()
          break
      }
      _onAFPlayerStateChange(event)
    }
  }

  setUpdateTimestampInterval () {
    updateTimestampId = window.setInterval(() => {
      this.updateTimestamp()
    }, updateTimestampInterval)
  }

  clearUpdateTimestamp () {
    window.clearInterval(updateTimestampId)
  }

  addPlayerStateListeners () {}

  remove () {
    if (this.player) {
      erasHeartbeater.afreecaTVHeartbeater.detach(this.player)
    }
    this.player = undefined
    this.clearUpdateTimestamp()
    // Afreeca Player doesn't have a destroy API, so we have to remove the IFrame element from the DOM
    // and set the player to undefined. When the player is recreated, a new unique id'd element
    // will be created to embed the player into
    $('[id^=video-player-afreeca-vod]').remove()
  }

  render () {
    // No need to render anything, the element responsible for rendering the player is created in createPlayer
    return null
  }
}

export default new VideoPlayerAfreeca()
