import { Component, Logger, router, $ } from 'lib'
import locale from 'Common/Locale/Locale'
import util from 'Common/Util/Util'
import informTooltip from 'Component/Inform/Tooltip/InformTooltip'
import rewardsOpting from 'Common/Service/Rewards/Opting/RewardsOpting'
import rewardsWatchHistory from 'Common/Service/Rewards/WatchHistory/RewardsWatchHistory'

class VodsGameSelector extends Component {
  constructor () {
    super()
    this.log = new Logger(this.constructor.name)
    this.addListeners()
  }

  addListeners () {
    this.link = (event) => {
      event.stopPropagation()
      event.preventDefault()
      const tournamentId = util.getDeepValue(this.props, 'tournament.id')

      this.props.onGameSelected
        && tournamentId
        && this.props.onGameSelected(tournamentId)
      const link = $(event.currentTarget).attr('href')

      // router.redirect does not trigger the navigation event
      // preventing the player from being removed (on VideoPlayer.js)
      // when clicking to watch the next game from the same match
      // after the current game ended (player ended event triggered)
      router.redirect(link, event)
    }
  }

  componentWillMount () {
    const tournamentId = util.getDeepValue(this.props, 'tournament.id')

    this.state = { showWatchAnnotations: false }

    if (tournamentId) {
      this.fetchWatchHistory(tournamentId)
      this.fetchOptingState()
    }
  }

  componentWillUnmount () {
    rewardsWatchHistory.reset()
  }

  fetchOptingState () {
    return rewardsOpting
      .fetchOptingState()
      .then((optingState) => {
        let { loggedOut: isLoggedOut, isOptedOut } = optingState

        isLoggedOut = isLoggedOut !== undefined && isLoggedOut
        isOptedOut = isOptedOut !== undefined && isOptedOut

        this.setState({ showWatchAnnotations: !isLoggedOut && !isOptedOut })
      })
      .catch((error) => {
        this.log.error(error)
      })
  }

  fetchWatchHistory (tournamentId) {
    return rewardsWatchHistory
      .fetchWatchHistory(tournamentId)
      .then((watchedContent) => {
        this.setState({ watchedContent })
      })
      .catch((error) => {
        this.log.error(error)
      })
  }

  renderUnneededGame (matchId, gameNumber) {
    const { showWatchAnnotations } = this.state
    const gameKey = matchId + ':' + gameNumber
    const spoiled = rewardsWatchHistory.isGameSpoiled(gameKey)
    const spoiledClasses = util.classNames(
      'game',
      'not-played',
      showWatchAnnotations && spoiled && 'spoiled',
      showWatchAnnotations && !spoiled && 'watch-annotated'
    )

    const gameSelectorElement = (
      <a
        href={ `/vod/${matchId}/${gameNumber}` }
        class={ spoiledClasses }
        tabindex="0">
        { gameNumber }
      </a>
    )

    gameSelectorElement.attributes.onfocusin = (event) => {
      const focusedGameNumber = event.currentTarget.innerText
      const gameKey = matchId + ':' + focusedGameNumber

      // Notify that we've spoiled this vod so the player no longer sees the annotation
      if (rewardsWatchHistory.setSpoiled(gameKey)) {
        $(event.currentTarget).addClass('spoiled')
        $(event.currentTarget).removeClass('watch-annotated')
      }
    }

    informTooltip.addTooltip(
      gameSelectorElement,
      locale.translate('match.endedEarly'),
      util.getDeepValue(this.props, 'options.tooltipAlignment') || 'right'
    )

    return gameSelectorElement
  }

  renderGame (matchId, game, gameNumber, selectedGame) {
    const watchedContentInfo
      = util.getDeepValue(this.state, 'watchedContent.' + game.id) || {}
    const isWatched
      = watchedContentInfo.entry_count
      >= rewardsWatchHistory.minutesWatchedThreshold
    const { showWatchAnnotations } = this.state
    const classes = util.classNames(
      'game',
      'game' + gameNumber,
      selectedGame === gameNumber && 'selected',
      showWatchAnnotations && isWatched && 'watched',
      showWatchAnnotations && 'watch-annotated'
    )

    const gameSelectorElement = (
      <a
        href={ `/vod/${matchId}/${gameNumber}` }
        class={ classes }
        onclick={ this.link }>
        { gameNumber }
      </a>
    )

    // if a game has no VODs, display "vod not available" tooltip
    if (!game.vods.length) {
      informTooltip.addTooltip(
        gameSelectorElement,
        locale.translate('message.vodNotAvailable'),
        util.getDeepValue(this.props, 'options.tooltipAlignment') || 'right'
      )
    }

    return gameSelectorElement
  }

  renderGames (matchId, games, selectedGame) {
    return (
      <div class="games">
        <span class="label">{ locale.translate('match.game') }</span>
        { games.map((game, index) =>
          game.state === 'unneeded'
            ? this.renderUnneededGame(matchId, index + 1)
            : this.renderGame(matchId, game, index + 1, selectedGame)
        ) }
      </div>
    )
  }

  render () {
    const { match, games } = this.props

    if (!match || !games) {
      return
    }

    const selectedGame = parseInt(router.param('gameNumber'))
    const matchId = match.id || router.param('matchId')
    const selectionClass
      = util.getDeepValue(this.props, 'options.activeSelection')
      && 'active-selection'

    return (
      <div class={ util.classNames(this.constructor.name, selectionClass) }>
        { this.renderGames(matchId, games, selectedGame) }
      </div>
    )
  }
}

export default VodsGameSelector
