import { erasHeartbeater, Logger, store } from 'lib'
import util from 'Common/Util/Util'
import watchUtil from 'Container/Watch/Util/WatchUtil'
import videoPlayerUtil from 'Component/Video/Player/Util/VideoPlayerUtil'

const scriptUrl
  = 'https://public.openrec.tv/external/static/js/embedPlayer.js?v1'

// ID used to cancel current updateTimestamp interval
let updateTimestampId = 0

// Updates how often we get currentTime from the player
const updateTimestampInterval = 0.5 * util.times.SECOND

const scrubbingThreshold = util.times.SECOND

/**
 * This is a regular class. It is one of the specialized video provider
 * classes used by the main VideoPlayer component to manage specific API code
 * for each provider, in this case, OpenRec
 */
class VideoPlayerOpenRec {
  constructor () {
    this.log = new Logger(this.constructor.name)
    this.state = videoPlayerUtil.states.unstarted
    this.startTimestamp = 0
    this.offset = 0
    this.addListeners()
  }

  addListeners () {
    // this happens once, don't need to be removed
    store.onChange('watch.initialTimestamp', (initialTimestamp) => {
      this.startTimestamp = Date.parse(initialTimestamp)
    })

    // this happens once, don't need to be removed
    store.onChange('watch.offset', (offset) => {
      this.offset = offset
    })

    // Reset the expected video time whenever a new video is selected
    // to avoid a spurious scrubbing event from being triggered by
    // carrying over the expected time from the previous video
    store.onChange(
      'watch.url',
      this.onUrlUpdate = () => {
        this.videoExpectedTime = 0
      }
    )
  }

  setState (state) {
    this.state = state
    this.log.info('State changed to', videoPlayerUtil.getStateString(state))
    store.set('videoPlayer.state', this.state)
    this.clearUpdateTimestamp()

    if (this.state === videoPlayerUtil.states.playing) {
      this.updateTimestamp()
      this.setUpdateTimestampInterval()
    }
  }

  checkIfScrubbing (videoCurrentTime) {
    this.videoExpectedTime
      = this.videoExpectedTime <= 0
        ? this.getVideoCurrentTime()
        : this.videoExpectedTime + updateTimestampInterval

    const didScrub
      = Math.abs(this.videoExpectedTime - videoCurrentTime) > scrubbingThreshold

    if (didScrub) {
      // Keeping the store variable value as undefined prevents it from being
      // called after the onChange function is re-initialized
      store.set('videoPlayer.dismissJumpButton', undefined)
    }

    this.videoExpectedTime = videoCurrentTime
  }

  getState () {
    return this.state
  }

  getVideoCurrentTime () {
    if (!this.player) return 0

    // VOD example: 1970-01-01T03:19:54.194Z (at 3:19:54 playback time)
    // Livestream example: 2020-03-25T08:57:46.057Z
    // Note: embed livestreams do not have scrubbing available
    if (this.player.currentDateTime) {
      // Return as unix timestamp
      return new Date(this.player.currentDateTime).getTime()
    }

    return 0
  }

  play () {
    this.player && this.player.play()
  }

  updateTimestamp () {
    const videoCurrentTime = this.getVideoCurrentTime()

    this.checkIfScrubbing(videoCurrentTime)

    const timestamp
      = this.streamType === watchUtil.streamTypes.live
        ? this.getVideoCurrentTime() + this.offset
        : this.startTimestamp + this.getVideoCurrentTime() + this.offset

    store.set('videoPlayer.timestamp', timestamp)
  }

  setUpdateTimestampInterval () {
    updateTimestampId = window.setInterval(() => {
      this.player && this.updateTimestamp()
    }, updateTimestampInterval)
  }

  clearUpdateTimestamp () {
    window.clearInterval(updateTimestampId)
  }

  configureHeartbeater () {
    erasHeartbeater.unsupportedPlayerHeartbeater.setUnsupportedPlayerConfig({
      source: 'openrec'
    })
  }

  pause () {
    this.player && this.player.pause()
  }

  remove () {
    this.log.debug('Removing player')
    this.clearUpdateTimestamp()
    if (this.player) {
      // Removes all event handlers
      this.player.destroy()
      // Removes iframe
      const playerNode = document.getElementById('video-player-openrec')

      if (playerNode) {
        while (playerNode.firstChild) {
          playerNode.removeChild(playerNode.firstChild)
        }
      }
      this.player = undefined
    }
  }

  loadById (tournamentId, uuid, streamType) {
    this.tournamentId = tournamentId
    this.uuid = uuid
    this.streamType = streamType
    this.configureHeartbeater()
    this.log.debug('Loading video with id', this.uuid)
    this.remove()
    this.createPlayer()
  }

  addToPlaylist () {
    // for compatibility between all player providers
  }

  setup (tournamentId, uuid, streamType) {
    this.tournamentId = tournamentId
    this.uuid = uuid
    this.streamType = streamType

    window.require([scriptUrl], () => {
      this.createPlayer()
    })
  }

  createPlayer () {
    this.player = new window.OR.Player('video-player-openrec', {
      height: '100%',
      width: '100%',
      movieId: this.uuid
    })

    this.addPlayerStateListeners()

    store.set('videoPlayer.timestamp', this.getVideoCurrentTime())
    this.configureHeartbeater()
  }

  addPlayerStateListeners () {
    this.player.on(window.OR.Player.Event.Play, () =>
      this.setState(videoPlayerUtil.states.playing)
    )

    this.player.on(window.OR.Player.Event.Pause, () =>
      this.setState(videoPlayerUtil.states.paused)
    )
  }

  render () {
    return <div id="video-player-openrec"/>
  }
}

export default new VideoPlayerOpenRec()
