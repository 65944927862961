import { Component, $ } from 'lib'
import Icon from 'Component/Asset/Icon/Icon'
import util from 'Common/Util/Util'

class VodsTournaments extends Component {
  constructor () {
    super()
    this.state.expanded = false
    this.addListeners()
  }

  addListeners () {
    this.selectTournamentListener = (tournamentSlug) => {
      this.props.tournamentChangeHandler
        && this.props.tournamentChangeHandler(tournamentSlug)
    }

    this.toggleTournamentSelector = () => {
      this.setState({
        tournamentSelectorActive: !this.state.tournamentSelectorActive
      })
    }

    $(document).on(
      'click',
      this.clickListener = (event) => {
        if (
          this.state.tournamentSelectorActive
          && !$(event.target).closest(this.base).length
        ) {
          this.setState({ tournamentSelectorActive: false })
        }
      }
    )
  }

  componentWillUnmount () {
    $(document).off('click', this.clickListener)
  }

  renderTournaments (selectedTournament, tournaments) {
    return tournaments.map((tournament) => {
      const selected = tournament.id === selectedTournament.id
      const selectedClass = selected ? 'selected' : ''

      return (
        <li
          role="button"
          class={ util.classNames('tournament', selectedClass) }
          onclick={ () => this.selectTournamentListener(tournament.slug) }>
          { tournament.slug }
        </li>
      )
    })
  }

  render () {
    const { selectedTournament, tournaments, selectedLeague } = this.props
    const tournamentSelectorActive = this.state.tournamentSelectorActive
    const tournamentListClasses = util.classNames(
      'tournament-list',
      tournamentSelectorActive && 'active'
    )
    const arrowDirection = tournamentSelectorActive ? 'up' : 'down'

    return (
      <div
        role="button"
        class={ this.constructor.name }
        onclick={ () => this.toggleTournamentSelector() }>
        <div class="current-tournament">{ selectedTournament.slug }</div>
        <Icon name="arrow" direction={ arrowDirection }/>
        <ul class={ tournamentListClasses }>
          { this.renderTournaments(
            selectedTournament,
            tournaments,
            selectedLeague
          ) }
        </ul>
      </div>
    )
  }
}

export default VodsTournaments
