import { Logger, $, store } from 'lib'
import locale from 'Common/Locale/Locale'
import util from 'Common/Util/Util'
import settingsService from 'Common/Service/Settings/Settings'

const fallbackStreamLocale = 'en-US'
const userConfigKeySuffix = 'watch-config'
const defaultProviderByLocale = {
  'en-AU': 'twitch',
  'es-MX': 'twitch',
  'ja-JP': 'twitch',
  'ko-KR': 'afreecatv',
  'pt-BR': 'twitch',
  'tr-TR': 'nimotv'
}

const providersWithoutIESupport = ['nimotv', 'openrec']

// Disabled providers for random selection. Can still be selected if it is the
// only provider available or if set as the default provider in
// `defaultProviderByLocale` or as user's preference
const disabledProviders = ['afreecatv']

// Riotbar region names from here:
// https://gh.riotgames.com/web/riotbar/blob/13f83f15157669d55d5493eda1635cfaabd3a2d5/src/i18n.js#L63
const defaultLeagueByRegion = {
  na: 'lcs',
  euw: 'lec',
  eune: 'lec',
  lan: 'lla',
  las: 'lla',
  br: 'cblol-brazil',
  tr: 'turkiye-sampiyonluk-ligi',
  ru: 'lec',
  oce: 'oce-opl',
  kr: 'lck',
  jp: 'ljl-japan'
}

class WatchUtil {
  constructor () {
    this.log = new Logger(this.constructor.name)
  }

  getDefaultLeagueByRegion (region) {
    return defaultLeagueByRegion[region]
  }

  shuffle (streams) {
    for (let i = streams.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      const temp = streams[i]

      streams[i] = streams[j]
      streams[j] = temp
    }
  }

  // Return stream from the streams parameter in order of priority:
  // 1. Stream having the passed in streamId
  // 2. Stream by passed-in provider from settings service in user's locale
  // 2.5. Stream provider from settings service matching partial locale ("en" from en-US)
  // 3. Stream by country (geolocation) when more than one stream is available with user's locale
  // 4. Stream by default provider by locale
  // 5. Stream matching the locale of the user
  // 6. Stream matching same language of user, but different locale (e.g. es-ES/es-MX)
  // 7. Stream matching the fallback locale
  // 8. First stream in the streams list
  getInitialPrioritizedStream (streams, streamId) {
    const streamById = streams.find((stream) => stream.parameter === streamId)

    if (streamById) return streamById

    const streamFromSettings = this.getProviderFromSettings(streams)

    if (streamFromSettings) return streamFromSettings

    const streamByCountry = this.getStreamByCountry(streams)

    if (streamByCountry) return streamByCountry

    const streamByDefaultProvider = this.getStreamByDefaultProvider(streams)

    if (streamByDefaultProvider) return streamByDefaultProvider

    // from this point forward we aren't picking a specific provider
    // so we randomize order to evenly split video providers
    this.shuffle(streams)

    const streamBySiteLocale = streams.find(
      (stream) =>
        stream.locale === locale.get()
        && !disabledProviders.includes(stream.provider)
    )

    if (streamBySiteLocale) return streamBySiteLocale

    const streamByLanguageCode = this.getSameLanguageStream(streams)

    if (streamByLanguageCode) return streamByLanguageCode

    const streamByFallbackLocale = this.getFallbackStream(streams)

    if (streamByFallbackLocale) return streamByFallbackLocale

    return streams.length ? streams[0] : this.log.error('No stream found.')
  }

  getStreamByCountry (streams) {
    const geoInfo = locale.getGeoInfo()

    return streams.find(
      (stream) =>
        stream.countries
        && stream.countries.length > 0
        && stream.countries.includes(geoInfo.code)
        && stream.locale === locale.get()
    )
  }

  getProviderFromSettings (streams) {
    const settings = store.get('settings')
    const provider = util.getDeepValue(
      settings,
      'watchSettings.defaultVideoProvider'
    )

    // user locale "en-US" only matches "en-US" stream locale
    const stream = streams.find(
      (stream) => stream.provider === provider && stream.locale === locale.get()
    )

    if (stream) return stream

    // partial locale (language): user locale "en-US" matches "en-GB" stream locale
    const language = locale.getParts().language

    return streams.find(
      (stream) =>
        stream.provider === provider
        && locale.getParts(stream.locale).language === language
    )
  }

  getStreamByDefaultProvider (streams) {
    const defaultProvider = streams.find(
      (stream) =>
        stream.provider === defaultProviderByLocale[locale.get()]
        && stream.locale === locale.get()
    )

    return defaultProvider
  }

  getStreamByParameter (streams, parameter) {
    const streamByParam = streams.find(
      (stream) => stream.parameter === parameter
    )

    return streamByParam
  }

  // Return stream from the streams parameter in order of priority:
  // 1. Stream having the passed in locale
  // 2. Stream matching the fallback locale
  // 3. First stream in the streams list
  getStreamByLocale (streams, locale) {
    const streamByLocale = streams.find((stream) => stream.locale === locale)

    if (streamByLocale) return streamByLocale

    this.log.warn(
      `No stream found for ${locale}, `
        + `falling back to ${fallbackStreamLocale}`
    )

    const streamByFallbackLocale = this.getFallbackStream(streams)

    if (streamByFallbackLocale) return streamByFallbackLocale

    this.log.warn(
      `No stream found for ${fallbackStreamLocale}, `
        + `falling back to first stream in streams list: ${streams}`
    )

    return streams.length ? streams[0] : this.log.error('No stream found.')
  }

  getSameLanguageStream (streams) {
    const userLanguageCode = locale.get().slice(0, 2)

    return streams.find(
      (stream) => stream.locale.slice(0, 2) === userLanguageCode
    )
  }

  getFallbackStream (streams) {
    return streams.find((stream) => stream.locale === fallbackStreamLocale)
  }

  getUserConfigKey (watchType) {
    return userConfigKeySuffix + '-' + watchType
  }

  getUserConfig (watchType) {
    const userConfigKey = this.getUserConfigKey(watchType)

    try {
      return JSON.parse(window.localStorage.getItem(userConfigKey))
    }
    catch (ex) {
      this.log.error('Bad user config data. Resetting.')
      window.localStorage.removeItem(userConfigKey)

      return {}
    }
  }

  getStatsLayoutFromSettings () {
    const settings = store.get('settings')

    return util.getDeepValue(settings, 'watchSettings.statsLayout')
  }

  updateStatsLayoutPreference (layout) {
    const settings = store.get('settings')

    if (settings) {
      settings.watchSettings.statsLayout = layout
      return settingsService.updatePreferences(settings)
    }

    store.onChange(
      'settings',
      this.settingsListener = (settings) => {
        settings.watchSettings.statsLayout = layout
        settingsService.updatePreferences(settings)

        store.removeListener('settings', this.settingsListener)
      }
    )
  }

  setUserConfig (key, value, watchType) {
    const userConfigKey = this.getUserConfigKey(watchType)
    const config = this.getUserConfig() || {}

    config[key] = value
    window.localStorage.setItem(userConfigKey, JSON.stringify(config))
  }

  forceVideoPlayerReattach () {
    $(window).trigger('resize')
  }

  isInGame (timestamp) {
    const initialTimestamp = store.get('watch.initialTimestamp')

    return (
      initialTimestamp !== undefined
      && timestamp !== 0
      && timestamp >= util.toMilliseconds(initialTimestamp)
    )
  }

  getStatsDisabledConditions (state) {
    const gameIsLive = state.currentGame !== undefined

    const offsetIsNotZero
      = state.selectedStream !== undefined
      && state.selectedStream.offset !== undefined
      && state.selectedStream.offset !== 0

    const isInGame = state.isInGame

    const hasFirstFrame
      = state.selectedStream && state.selectedStream.firstFrameTime

    return { gameIsLive, offsetIsNotZero, isInGame, hasFirstFrame }
  }

  conditionsHaveChanged (prevConditions, nextConditions) {
    const keys = Object.keys(nextConditions)

    for (let i = 0; i < keys.length; i++) {
      if (prevConditions[keys[i]] !== nextConditions[keys[i]]) return true
    }

    return false
  }

  statsDisabledLogging (prevState, nextState) {
    const previousConditions = this.getStatsDisabledConditions(prevState)
    const nextConditions = this.getStatsDisabledConditions(nextState)

    if (!this.conditionsHaveChanged(previousConditions, nextConditions)) return

    let loggingString = ''

    !nextConditions.gameIsLive && (loggingString += '\n - No game set to live')

    !nextConditions.hasFirstFrame
      && !nextConditions.offsetIsNotZero
      && (loggingString += '\n - Offset is set to 0')

    nextState.currentGame
      && nextState.currentGame.state === 'completed'
      && !nextConditions.offsetIsNotZero
      && !nextConditions.hasFirstFrame
      && (loggingString += '\n - First frame time is not set')

    !nextConditions.isInGame && (loggingString += '\n - Not in game')

    return loggingString !== ''
      ? this.log.info('Conditions changed, stats disabled: ' + loggingString)
      : this.log.info('Conditions changed, stats enabled! All conditions met')
  }

  get streamTypes () {
    return {
      live: 'live',
      vod: 'vod'
    }
  }

  removeProvidersWithoutIESupport (streams) {
    return streams.filter(
      (stream) => providersWithoutIESupport.indexOf(stream.provider) === -1
    )
  }
}

export default new WatchUtil()
