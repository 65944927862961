/* eslint max-lines: ["error", {"max": 450, "skipBlankLines": true, "skipComments": true}] */
import { $, Component, Logger, store } from 'lib'
import locale from 'Common/Locale/Locale'
import device from 'Common/Device/Device'
import config from 'Common/Config/Config'
import util from 'Common/Util/Util'
import watchUtil from 'Container/Watch/Util/WatchUtil'
import Icon from 'Component/Asset/Icon/Icon'
import VideoPlayer from 'Component/Video/Player/VideoPlayer'
import WatchMenu from 'Component/Navigation/WatchMenu/WatchMenu'
import WatchOptions from 'Component/Watch/Options/WatchOptions'
import WatchChat from 'Component/Watch/Chat/WatchChat'
import VodsGameSelector from 'Component/Vods/GameSelector/VodsGameSelector'
import EventMatchScore from 'Component/Event/Match/Score/EventMatchScore'
import EventShowHeader from 'Component/Event/Show/Header/EventShowHeader'
import StatsTeams from 'Component/Stats/Teams/StatsTeams'
import StatsMatchup from 'Component/Stats/Matchup/StatsMatchup'
import InformLoading from 'Component/Inform/Loading/InformLoading'
import settingsService from 'Common/Service/Settings/Settings'
import LogInReminder from 'Component/Rewards/LogInReminder/LogInReminder'
import riotBar from 'Common/Service/RiotBar/RiotBar'

// determines the tabs to show in the selector
// must have an svg in Icon.js with the same name
const statsTabList = ['stats', 'items', 'abilities', 'runes']
const settingsTimeout = config.isNonProdEnvironment()
  ? 5 * util.times.SECONDS
  : 2 * util.times.SECONDS

class Watch extends Component {
  constructor (props) {
    super(props)
    this.log = new Logger(this.constructor.name)
    this.watchType = props.watchType
    this.addListeners()
    this.initializePreferencesFromSettings()
    this.initializeState()
  }

  initializePreferencesFromSettings () {
    const settings = store.get('settings')

    if (settings) {
      const layout = watchUtil.getStatsLayoutFromSettings()

      return this.setState({ layout })
    }

    const settingsListenerStart = Date.now()

    store.onChange(
      'settings',
      this.settingsListener = () => {
        if (Date.now() - settingsListenerStart > settingsTimeout) return // timed out

        const layout = watchUtil.getStatsLayoutFromSettings()

        this.setState({ layout })
        store.removeListener('settings', this.settingsListener)
      }
    )

    window.setTimeout(() => {
      if (this.state.layout) return // settings already fetched

      this.log.warn('Settings fetch timed out! Using default settings.')
      const layout = util.getDeepValue(
        settingsService.getDefaultSettings(),
        'watchSettings.statsLayout'
      )

      this.setState({ layout })
    }, settingsTimeout)
  }

  initializeState () {
    const userWatchConfig = watchUtil.getUserConfig(this.watchType)
    const chatActive = userWatchConfig && userWatchConfig.chatActive || false

    this.setState({
      deviceSize: device.getSize(),
      selectedMenuTab: 'stats', // TODO: smart tab select?
      selectedStatsTab: statsTabList[0],
      chatActive
    })
  }

  componentWillUnmount () {
    $(document).off('mediaSizeChange')

    // reset livestats specific values
    store.set('watch.offset', 0)
    store.set('watch.initialTimestamp', undefined)
  }

  addListeners () {
    $(document).on('mediaSizeChange', (event) => {
      this.setState({ deviceSize: event.data })
    })

    this.menuTabChangeHandler = (selectedMenuTab) => {
      this.setState({ selectedMenuTab })
    }

    this.statsTabChangeHandler = (selectedStatsTab) => {
      this.setState({ selectedStatsTab })
    }

    this.toggleSmallOptions = () => {
      this.setState({
        smallOptionsActive: !this.state.smallOptionsActive
      })
    }

    this.toggleChat = () => {
      watchUtil.setUserConfig(
        'chatActive',
        !this.state.chatActive,
        this.watchType
      )

      this.setState(
        {
          chatActive: !this.state.chatActive
        },
        watchUtil.forceVideoPlayerReattach
      )
    }

    this.streamChangeHandler = (selectedStream) => {
      if (selectedStream !== this.props.selectedStream) {
        // should close the small options menu if the provider is the same
        const sameProvider
          = this.props.selectedStream.provider === selectedStream.provider

        this.props.streamChangeHandler
          && this.props.streamChangeHandler(selectedStream)

        store.set('watch.offset', selectedStream.offset)

        this.setState({
          smallOptionsActive: !sameProvider
        })
      }
    }

    this.layoutChangeHandler = (layout) => {
      watchUtil.updateStatsLayoutPreference(layout)

      if (layout !== this.state.layout) {
        this.setState({ layout }, watchUtil.forceVideoPlayerReattach)
      }
    }

    this.smallShowMatchupHandler = (visible) => {
      if (this.state.smallShowMatchup === undefined) {
        this.state.smallShowMatchup = false // do not display player on first render
      }
      else {
        this.setState({ smallShowMatchup: visible })
      }
    }
  }

  renderEventHeader () {
    switch (this.props.event.type) {
      case 'match':
        return (
          <EventMatchScore
            teams={ this.props.event.match.teams }
            hideScore="true"
            separator={ locale.translate('match.vs') }
          />
        )
      case 'show':
        return <EventShowHeader league={ this.props.event.league }/>
      default:
        this.log.warn('Unhandled event type:', this.props.event.type)
        return
    }
  }

  renderVodsGameSelector () {
    return (
      this.watchType === watchUtil.streamTypes.vod && (
        <VodsGameSelector
          match={ this.props.event.match }
          games={ this.props.event.match.games }
          tournament={ this.props.event.tournament }
          options={ { tooltipAlignment: 'left', activeSelection: true } }
          onGameSelected={ this.props.onGameSelected }
        />
      )
    )
  }

  renderOverviewPane () {
    return (
      <div class="overview-pane">
        <div class="event-header">{ this.renderEventHeader() }</div>
        { this.watchType === watchUtil.streamTypes.vod
          && this.renderVodsGameSelector() }
        { this.props.eventHasStats
          && <StatsTeams gameId={ this.props.gameId } watchType={ this.watchType }/>
        }
      </div>
    )
  }

  renderChatToggle () {
    if (this.props.chatServiceAvailable) {
      return (
        <div class="chat-toggle" onclick={ this.toggleChat } role="button">
          <Icon name={ this.state.chatActive ? 'chatClose' : 'chatOpen' }/>
        </div>
      )
    }
  }

  renderChat () {
    if (this.props.chatServiceAvailable) {
      return (
        <div class="chat-pane">
          <WatchChat
            provider={ this.props.selectedStream.provider }
            providerId={ this.props.selectedStream.parameter }
          />
        </div>
      )
    }
  }

  renderStatsMatchup (hideForSmall) {
    return (
      this.props.eventHasStats && (
        <StatsMatchup
          gameId={ this.props.gameId }
          statsTabChangeHandler={ this.statsTabChangeHandler }
          selectedTab={ this.state.selectedStatsTab }
          tabList={ statsTabList }
          hideForSmall={ hideForSmall }
          smallShowMatchupHandler={ this.smallShowMatchupHandler }
          watchType={ this.watchType }
        />
      )
    )
  }

  renderStats () {
    if (this.state.deviceSize === 'medium') {
      return this.renderStatsMatchup()
    }
    else if (this.state.deviceSize === 'small') {
      return (
        <div class="small-stats-container">
          { this.renderStatsMatchup(!this.state.smallShowMatchup) }
          { this.props.eventHasStats && (
            <StatsTeams
              gameId={ this.props.gameId }
              smallShowMatchupHandler={ this.smallShowMatchupHandler }
              watchType={ this.watchType }
            />
          ) }
        </div>
      )
    }
  }

  renderWatchOptions () {
    return (
      <WatchOptions
        selectedStream={ this.props.selectedStream }
        streams={ this.props.streams }
        selectedLayout={ this.state.layout }
        streamChangeHandler={ this.streamChangeHandler }
        layoutChangeHandler={ this.layoutChangeHandler }
      />
    )
  }

  renderWatchMenu (chatTab) {
    const tabs = []

    this.props.eventHasStats && tabs.push('stats')
    chatTab && this.props.chatServiceAvailable && tabs.push('chat')

    return (
      <WatchMenu
        tournamentId={ this.props.event.tournament.id }
        gameId={ this.props.gameId }
        tabs={ tabs }
        tabChangeHandler={ this.menuTabChangeHandler }
        selectedMenuTab={ this.state.selectedMenuTab }
        watchType={ this.watchType }
      />
    )
  }

  renderNav (chatTab) {
    const navClasses = util.classNames(
      'nav',
      this.state.smallOptionsActive && 'small-options-active'
    )

    return (
      <div class={ navClasses }>
        { this.renderWatchMenu(chatTab) }
        <div class="stream-selector">{ this.renderWatchOptions() }</div>
        { !chatTab && this.renderChatToggle() }
      </div>
    )
  }

  renderTabContent () {
    const selectedMenuTab = this.state.selectedMenuTab

    if (selectedMenuTab === 'chat') {
      return this.renderChat()
    }
    else if (selectedMenuTab === 'stats') {
      return this.renderStats()
    }
  }

  renderLogInReminder () {
    if (
      riotBar.isLoggedIn()
      || window.sessionStorage.getItem('loginDismissed') === 'true'
    ) {
      return
    }

    return <LogInReminder/>
  }

  renderVideoPlayer () {
    const gameId = this.props.gameId
    const current
      = this.props.timeline
      && this.props.timeline.find((event) => event.gameId === gameId)
    const timelineEvent = current && {
      matchId: current.matchId,
      gameNumber: current.gameNumber,
      gameId: current.gameId
    }

    return (
      <VideoPlayer
        gameId={ gameId }
        streamLocale={ this.props.selectedStream.locale }
        uuid={ this.props.selectedStream.parameter }
        tournamentId={ this.props.event.tournament.id }
        leagueSlug={ this.props.event.league.slug }
        provider={ this.props.selectedStream.provider }
        streamType={ watchUtil.streamTypes[this.watchType] }
        startMillis={ this.props.selectedStream.startMillis }
        endMillis={ this.props.selectedStream.endMillis }
        timelineEvent={ timelineEvent }
        timeline={ this.props.timeline }
      />
    )
  }

  renderSmall () {
    const containerClasses = util.classNames(this.constructor.name, 'small')
    const lowerClasses = util.classNames(
      'lower',
      this.state.smallOptionsActive && 'small-options-active'
    )

    return (
      <main class={ containerClasses }>
        <div class="center-pane">
          <div class="upper">
            { this.renderLogInReminder() }
            { this.renderVideoPlayer() }
          </div>
          <div class={ lowerClasses }>
            <div
              class="event-header"
              onclick={ () => this.toggleSmallOptions() }
              role="button">
              { this.renderEventHeader() }
              <Icon name="arrow" direction="down"/>
            </div>
            <div class="stream-selector">
              { this.renderVodsGameSelector() }
              { this.renderWatchOptions() }
            </div>
            { this.renderWatchMenu(true) }
            <div class="secondary-info">{ this.renderTabContent() }</div>
          </div>
        </div>
      </main>
    )
  }

  renderMedium () {
    const containerClasses = util.classNames(this.constructor.name, 'medium')

    return (
      <main class={ containerClasses }>
        <div class="center-pane">
          <div class="upper">
            { this.renderLogInReminder() }
            { this.renderVideoPlayer() }
          </div>
          <div class="lower">
            { this.renderOverviewPane() }
            <div class="nav-details">
              { this.renderNav(true) }
              { this.renderTabContent() }
            </div>
          </div>
        </div>
      </main>
    )
  }

  renderLarge () {
    // force large to function normally when resizing
    if (this.state.selectedMenuTab === 'chat') {
      this.state.chatActive = true
      this.state.selectedMenuTab = 'stats'
    }

    const containerClasses = util.classNames(this.constructor.name, 'large')

    return (
      <main class={ containerClasses }>
        { this.state.layout === 'sidebar' && this.renderOverviewPane() }
        <div class="center-pane">
          <div class="upper">
            { this.renderLogInReminder() }
            { this.renderVideoPlayer() }
          </div>
          <div class="lower">
            { this.state.layout === 'theater' && this.renderOverviewPane() }
            <div class="nav-details">
              { this.renderNav(false) }
              { this.renderStatsMatchup() }
            </div>
          </div>
        </div>
        { this.state.chatActive && this.renderChat() }
      </main>
    )
  }

  renderLoading () {
    return (
      <main class={ this.constructor.name }>
        <InformLoading/>
      </main>
    )
  }

  render () {
    const { watchType, event, selectedStream, streams } = this.props

    if (!watchType || !event || !selectedStream || !streams) {
      return this.log.error('Missing mandatory properties')
    }

    const { layout } = this.state

    if (!layout) return this.renderLoading()

    // Because of the extreme differences in layouts, each size is rendered separately
    if (this.state.deviceSize === 'small') {
      return this.renderSmall()
    }
    else if (this.state.deviceSize === 'medium') {
      return this.renderMedium()
    }
    else {
      return this.renderLarge()
    }
  }
}

export default Watch
