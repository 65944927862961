import { Component, Logger, router, $, store } from 'lib'
import InformLoading from 'Component/Inform/Loading/InformLoading'
import InformBubble from 'Component/Inform/Bubble/InformBubble'
import relApi from 'Common/Service/RelApi/RelApi'
import liveStats from 'Common/Service/LiveGameEvents/LiveGameEvents'
import locale from 'Common/Locale/Locale'
import Watch from 'Component/Watch/Watch'
import watchUtil from 'Container/Watch/Util/WatchUtil'
import watchHistory from 'Common/Service/Rewards/WatchHistory/RewardsWatchHistory'
import analytics from 'Common/Service/Analytics/Analytics'

class WatchVod extends Component {
  constructor () {
    super()
    this.log = new Logger(this.constructor.name)
    this.addEventListeners()
  }

  addEventListeners () {
    this.streamChangeHandler = (selectedStream) => {
      this.setState(
        { selectedStream, isInGame: false },
        watchUtil.forceVideoPlayerReattach
      )
    }

    store.onChange(
      'videoPlayer.timestamp',
      this.gamePhaseListener = (timestamp) => {
        const isInGame = watchUtil.isInGame(timestamp)

        this.state.isInGame !== isInGame && this.setState({ isInGame })
      }
    )
  }

  componentWillUnmount () {
    store.removeListener('videoPlayer.timestamp', this.gamePhaseListener)
    $(document).off('click', this.clickListener)
  }

  getUrlParams () {
    const matchId = router.param('matchId')
    const gameNumber = Number(router.param('gameNumber')) || 1
    const streamId = router.param('streamId')

    return { matchId, gameNumber, streamId }
  }

  updateUrl () {
    const url
      = '/vod/'
      + router.param('matchId')
      + '/'
      + router.param('gameNumber')
      + '/'
      + this.state.selectedStream.parameter

    router.updateUrl(url)
    store.set('watch.url', url)
    analytics.trackEvent('VOD started', {
      gameSelected: router.param('gameNumber')
    })
  }

  componentDidMount () {
    const { matchId, gameNumber, streamId } = this.getUrlParams()

    matchId
      ? this.fetchMatch(matchId, gameNumber, streamId)
      : window.location.pathname = `/${locale.get()}/schedule`
  }

  componentWillUpdate (nextProps, nextState) {
    if (!this.state.event || !this.state.selectedStream || this.state.error) {
      return // on the first update we are still getting data from the server
    }
    watchUtil.statsDisabledLogging(this.state, nextState)

    const { matchId, gameNumber, streamId } = this.getUrlParams()

    if (this.state.event.id !== matchId) {
      this.log.info('Changing match to', matchId)
      this.fetchMatch(matchId, gameNumber, streamId)
    }
    else {
      const currentGame = this.state.event.match.games[gameNumber - 1]

      const nextStateParameter = nextState.selectedStream.parameter

      let selectedStream = watchUtil.getStreamByParameter(
        currentGame.vods,
        nextStateParameter
      )

      if (!selectedStream) {
        selectedStream = watchUtil.getStreamByLocale(
          currentGame.vods,
          this.state.selectedStream.locale
        )
      }

      store.set('watch.offset', selectedStream.offset)

      if (selectedStream.firstFrameTime) {
        store.set('watch.firstFrameTime', selectedStream.firstFrameTime)
      }

      if (currentGame.id !== nextState.currentGame.id) {
        this.log.info('Changing game to', currentGame.id)
        this.setInitialGameTimestamps(currentGame.id)
      }

      this.setState({ currentGame, selectedStream }, this.updateUrl)
    }
  }

  setInitialGameTimestamps (gameId) {
    return liveStats
      .fetchInitialTimestamp(gameId)
      .then((frames) => {
        const initialTimestamp = frames[0].rfc460Timestamp

        this.log.info('Initial timestamp set to', initialTimestamp)
        store.set('watch.initialTimestamp', initialTimestamp)
      })
      .catch((error) => {
        this.log.error(error)
      })
  }

  fetchMatch (matchId, gameNumber, streamId) {
    return relApi
      .fetchEventDetails(matchId)
      .then((event) => {
        const currentGame = event.match.games[gameNumber - 1]
        const selectedStream = watchUtil.getInitialPrioritizedStream(
          currentGame.vods,
          streamId
        )

        if (!selectedStream) {
          throw Error(locale.translate('message.noStreams'))
        }

        store.set('watch.offset', selectedStream.offset)
        this.setInitialGameTimestamps(currentGame.id)
        this.setState({ event, currentGame, selectedStream }, this.updateUrl)
        this.fetchTournamentVods(event.tournament.id, selectedStream.parameter)
      })
      .catch((error) => {
        this.setState({ error })
        this.log.error(error)
      })
  }

  fetchWatchHistory (tournamentId) {
    if (!tournamentId) {
      return
    }

    watchHistory.reset()

    return watchHistory
      .fetchWatchHistory(tournamentId)
      .then((watchedContent) => {
        this.setState({ watchedContent })
      })
      .catch((error) => {
        this.log.error(error)
      })
  }

  fetchTournamentVods (tournamentId, streamId) {
    if (!tournamentId || !streamId) {
      return
    }

    return relApi
      .fetchVods(tournamentId)
      .then(({ events }) => {
        this.setState({ timeline: this.buildTimeline(events, streamId) })
      })
      .catch((error) => {
        this.log.error(error)
      })
  }

  buildTimeline (events, streamId) {
    const timeline = []

    events.forEach((event) => {
      const completedGames = event.games.filter(
        (game) => game.state === 'completed'
      )

      if (!completedGames) return

      const matchId = event.match.id

      completedGames.forEach((game, index) => {
        const matchingVods = game.vods.filter(
          (vod) => vod.parameter === streamId
        )

        if (!matchingVods) return

        const entity = {}

        entity.gameId = game.id
        entity.gameNumber = index + 1

        matchingVods.forEach((vod) => {
          entity.startMillis = vod.startMillis
          entity.endMillis = vod.endMillis
        })

        timeline.push(
          Object.assign({}, entity, {
            matchId
          })
        )
      })
    })

    return timeline
  }

  eventHasStats () {
    const {
      offsetIsNotZero,
      hasFirstFrame,
      isInGame
    } = watchUtil.getStatsDisabledConditions(this.state)

    return (hasFirstFrame || offsetIsNotZero) && isInGame
  }

  renderErrorOrLoading (error) {
    return (
      <main class={ this.constructor.name }>
        { error ? (
          <InformBubble theme="error" icon="error">
            { error.message }
          </InformBubble>
        )
          : <InformLoading/>
        }
      </main>
    )
  }

  render () {
    const { event, currentGame, selectedStream, timeline } = this.state

    if (
      !event
      || !currentGame
      || !selectedStream
      || !timeline
      || this.state.error
    ) {
      return this.renderErrorOrLoading(this.state.error)
    }

    return (
      <main class={ this.constructor.name }>
        <Watch
          watchType={ watchUtil.streamTypes.vod }
          event={ event }
          gameId={ currentGame.id }
          selectedStream={ selectedStream }
          streams={ currentGame.vods }
          streamChangeHandler={ this.streamChangeHandler }
          eventHasStats={ this.eventHasStats() }
          onGameSelected={ (tournamentId) =>
            this.fetchWatchHistory(tournamentId)
          }
          timeline={ timeline }
        />
      </main>
    )
  }
}

export default WatchVod
