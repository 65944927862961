import { Component, Logger } from 'lib'
import locale from 'Common/Locale/Locale'
import util from 'Common/Util/Util'
import Icon from 'Component/Asset/Icon/Icon'

const layoutOptions = ['sidebar', 'theater']

class WatchOptionsLayout extends Component {
  constructor () {
    super()
    this.log = new Logger(this.constructor.name)
    this.addListeners()
  }

  addListeners () {
    this.switchLayout = (event) => {
      const newLayout = event.currentTarget.getAttribute('data-layout')

      this.props.layoutChangeHandler
        && this.props.layoutChangeHandler(newLayout)
    }
  }

  renderLayoutOption (option) {
    const optionClasses = util.classNames(
      'option',
      option === this.props.selectedLayout && 'selected'
    )

    return (
      <li
        class={ optionClasses }
        data-layout={ option }
        onclick={ this.switchLayout }>
        <span class="label">
          { locale.translate('watch.layoutSelection.' + option) }
        </span>
        <Icon name={ option }/>
      </li>
    )
  }

  renderLayoutOptions () {
    return (
      <div class="options-section layout-selection">
        <div class="title">{ locale.translate('watch.layoutSelection') }</div>
        <ul class="layouts options-list">
          { layoutOptions.map((option) => this.renderLayoutOption(option)) }
        </ul>
      </div>
    )
  }

  render () {
    const { layoutChangeHandler, selectedLayout } = this.props

    if (!layoutChangeHandler || !selectedLayout) {
      return this.log.error(
        'Missing mandatory properties for',
        this.constructor.name
      )
    }

    return <div class={ this.constructor.name }>{ this.renderLayoutOptions() }</div>
  }
}

export default WatchOptionsLayout
