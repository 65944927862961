/* eslint max-lines: ["error", {"max": 300, "skipBlankLines": true, "skipComments": true}] */
import { Component, Logger, router, store, $, render } from 'lib'
import youTubePlayer from 'Component/Video/Player/YouTube/VideoPlayerYouTube'
import twitchPlayer from 'Component/Video/Player/Twitch/VideoPlayerTwitch'
import nimoPlayer from 'Component/Video/Player/Nimo/VideoPlayerNimo'
import openrecPlayer from 'Component/Video/Player/OpenRec/VideoPlayerOpenRec'
import mildomPlayer from 'Component/Video/Player/Mildom/VideoPlayerMildom'
import afreecaPlayer from 'Component/Video/Player/Afreeca/VideoPlayerAfreeca'
import trovoPlayer from 'Component/Video/Player/Trovo/VideoPlayerTrovo'
import miniPlayer from 'Component/Video/Player/Mini/VideoPlayerMini'
import videoPlayerUtil from 'Component/Video/Player/Util/VideoPlayerUtil'
import analytics from 'Common/Service/Analytics/Analytics'

let $placeholder, $player, player, provider, shouldFloat, intervalId

let restoreUrl = router.currentUrl

const videoProvider = {
  youtube: youTubePlayer,
  twitch: twitchPlayer,
  nimotv: nimoPlayer,
  openrec: openrecPlayer,
  mildom: mildomPlayer,
  afreecatv: afreecaPlayer,
  trovo: trovoPlayer
}

class VideoPlayer extends Component {
  constructor (props) {
    super(props)
    this.log = new Logger(this.constructor.name)
    this.setShouldFloat(true) // allow player to float (miniPlayer)

    analytics.trackEvent('video_loaded', {
      provider: props.provider,
      gameId: props.gameId,
      league: props.league,
      locale: props.streamLocale,
      tournamentId: props.tournamentId,
      type: props.streamType
    })
  }

  addListeners () {
    $(document).on(
      'router.navigation.go',
      this.onNavigation = (data) => {
        this.setRestoreUrl(data.detail.from)
        this.hasStarted() ? this.float() : this.remove()
      }
    )

    $(window).on(
      'resize',
      this.onResize = () => {
        if (this.isAttached()) {
          this.attach() // reattaching recalculates player size
        }
      }
    )

    $(document).on(
      'scroll',
      this.onScroll = () => {
        if (
          this.isPlaceholderOnScreen()
          && this.isFloating()
          && this.isSameVideo()
        ) {
          this.attach()
        }
        else if (
          !this.isPlaceholderOnScreen()
          && this.isAttached()
          && this.hasStarted()
          && shouldFloat
        ) {
          this.float()
        }
      }
    )

    store.onChange(
      'watch.url',
      this.onUrlUpdate = (newUrl) => {
        this.setRestoreUrl(newUrl)
      }
    )
  }

  addStateChangeListener () {
    // This listener intentionally is not inside addListeners()
    // It is added/removed at a different time (not on class instantiation)
    store.onChange(
      'videoPlayer.state',
      this.videoPlayerStateListener = () => {
        miniPlayer.reset()
        if (this.hasEnded() && this.isFloating()) {
          // video ended
          this.isSameRoute() ? this.attach() : this.remove() // remove if not the original page the player came from; attach if it is
        }
      }
    )
  }

  setShouldFloat (value) {
    shouldFloat = value
  }

  getPlayer () {
    return player
  }

  play () {
    player.play()
  }

  pause () {
    player.pause()
  }

  restore () {
    router.go(restoreUrl)
  }

  remove () {
    window.clearInterval(intervalId)
    store.remove('videoPlayer.state')
    player && player.remove()
    $player && $player.remove()
    $player = player = undefined
    analytics.trackEvent('video_ended')
  }

  isFloating () {
    return $player && $player.hasClass('floating')
  }

  isAttached () {
    return $player && $player.hasClass('attached')
  }

  hasEnded () {
    return player && player.getState() === videoPlayerUtil.states.ended
  }

  isPaused () {
    return player && player.getState() === videoPlayerUtil.states.paused
  }

  hasStarted () {
    const playerState = player && player.getState()

    return (
      playerState === videoPlayerUtil.states.playing
      || playerState === videoPlayerUtil.states.paused
      || playerState === videoPlayerUtil.states.buffering
    )
  }

  isSameVideo () {
    const isSameParam = player && player.uuid === this.props.uuid

    if (
      player
      && player.startMillis
      && this.props.startMillis
      && player.endMillis
      && this.props.endMillis
    ) {
      return (
        isSameParam
        && player.startMillis === this.props.startMillis
        && player.endMillis === this.props.endMillis
      )
    }

    return isSameParam
  }

  isSameRoute () {
    return restoreUrl === router.currentUrl
  }

  isSamePlayerProps (prevProps) {
    return (
      player
      && prevProps.provider === this.props.provider
      && prevProps.streamLocale === this.props.streamLocale
      && prevProps.uuid === this.props.uuid
      && prevProps.startMillis === this.props.startMillis
      && prevProps.endMillis === this.props.endMillis
    )
  }

  componentWillUnmount () {
    $(window).off('resize', this.onResize)
    $(document).off('scroll', this.onScroll)
    $(document).off('router.navigation.go', this.onNavigation)
    store.removeListener('watch.url', this.onUrlUpdate)
  }

  isPlaceholderOnScreen () {
    const offset = window.pageYOffset
    const pos = $placeholder.offset()
    const height = $placeholder.height()

    return !(offset > (pos.top + height) / 1.6) // calculate if half of the placeholder is offscreen
  }

  isLoaded () {
    return (
      player !== undefined
      && miniPlayer !== undefined
      && provider === this.props.provider
    )
  }

  attach () {
    miniPlayer.hide()
    $player.removeClass('floating').addClass('attached')
    $player
      .css('height', $placeholder.height() + 'px')
      .css('width', $placeholder.width() + 'px')
      .css('left', $placeholder.offset().left + 'px')
  }

  float () {
    miniPlayer.show()
    $player
      .removeClass('attached')
      .addClass('floating animate') // animations start after floating
      .css('height', '')
      .css('width', '')
      .css('left', '')
  }

  create () {
    if (player) {
      // if player already exists at this point it means a
      // new player provider is being created so we should remove it first.
      // example: watching youtube then switching to twitch player
      this.remove()
    }

    provider = this.props.provider
    player = videoProvider[provider] // store a reference to the player.provider

    const videoPlayer = render(
      <div id="video-player">
        { miniPlayer.render() }
        { player.render() }
      </div>
    )

    // when navigating to other pages, we don't want the miniPlayer to be re-rendered. It is appended manually outside of any other elements
    $('body').append(videoPlayer)

    $player = $('#video-player')

    this.addStateChangeListener()
    player.setup(
      this.props.tournamentId,
      this.props.uuid,
      this.props.streamType,
      this.props.startMillis,
      this.props.endMillis,
      this.props.timelineEvent,
      this.props.timeline,
      this.props.leagueSlug,
      () => this.remove()
    )
    miniPlayer.setup(this, provider)
  }

  renderPlayer (forceLoadById) {
    if (!this.isLoaded()) {
      // on first load create the player and render
      this.create()
    }
    else if (!this.isSameVideo() || forceLoadById) {
      // on subsequent loads just reload the player with a new video ID and reset the icon to pause
      miniPlayer.reset()
      player.loadById(
        this.props.tournamentId,
        this.props.uuid,
        this.props.streamType,
        this.props.startMillis,
        this.props.endMillis,
        this.props.timelineEvent,
        this.props.timeline,
        this.props.leagueSlug,
        () => this.remove()
      )
    }

    this.attach()
  }

  componentDidMount () {
    $placeholder = $('#video-player-placeholder')
    this.addListeners()
    this.renderPlayer()
  }

  componentDidUpdate (prevProps) {
    // force load video if player exists and passed a new provider/locale/uuid
    player && !this.isSamePlayerProps(prevProps) && this.renderPlayer(true)
  }

  setRestoreUrl (url) {
    this.log.debug('Updating miniplayer restore url to', url)
    restoreUrl = url
  }

  render () {
    if (!(this.props.uuid && this.props.provider in videoProvider)) {
      return this.log.error(
        'Video uuid',
        this.props.uuid,
        'and provider',
        this.props.provider,
        ' are required'
      )
    }

    return (
      <div class={ this.constructor.name }>
        <div id="video-player-placeholder" uuid={ this.props.uuid }/>
      </div>
    )
  }
}

export default VideoPlayer
