import { Logger } from 'lib'
import riotBar from 'Common/Service/RiotBar/RiotBar'
import config from 'Common/Config/Config'
import serviceUtil from 'Common/Service/Util/ServiceUtil'

const spoiledGamesKey = 'spoiled-games'

const hosts = {
  local: '/mock-service/eras-watch/v1',
  dev: 'https://dev-raptor.rewards.lolesports.com/v1',
  test: 'https://qa-raptor.rewards.lolesports.com/v1',
  stage: 'https://stage-raptor.rewards.lolesports.com/v1',
  prod: 'https://raptor.rewards.lolesports.com/v1'
}

const minutesWatchedThreshold = 10
const env = window.sessionStorage.getItem('rewards-api-env') || config.env
const baseUrl = hosts[env]

class RewardsWatchHistory {
  constructor () {
    this.log = new Logger(this.constructor.name)
    this.puuid = riotBar.getPuuid()
    if (this.puuid) {
      this.spoiledGames = JSON.parse(
        window.localStorage.getItem(spoiledGamesKey) || '{}'
      )
      this.spoiledGamesByUser = this.spoiledGames[this.puuid] || {}
    }
    else {
      this.spoiledGames = {}
      this.spoiledGamesByUser = {}
    }
  }

  fetchWatchHistory (tournamentID, forceRefetch) {
    if (!riotBar.isLoggedIn()) {
      return Promise.resolve({ loggedOut: true })
    }

    if (!forceRefetch && this.watchHistoryPromise) {
      return this.watchHistoryPromise
    }

    const url = baseUrl + '/history/watch/' + tournamentID

    this.watchHistoryPromise = serviceUtil
      .fetch(url, {
        credentials: 'include',
        headers: {
          Authorization: 'Cookie __Secure-access_token'
        }
      })
      .then((json) => {
        if (!json) {
          return {}
        }

        return json.reduce((watchedContent, watched) => {
          const { content_id: contentId } = watched

          watched.game_id = contentId
          watchedContent[watched.game_id] = watched
          return watchedContent
        }, {})
      })

    return this.watchHistoryPromise
  }

  reset () {
    this.watchHistoryPromise = undefined
  }

  isGameSpoiled (gameKey) {
    return !!this.spoiledGamesByUser[gameKey]
  }

  setSpoiled (gameKey) {
    if (!riotBar.isLoggedIn()) {
      return false
    }

    this.spoiledGamesByUser[gameKey] = 1
    this.spoiledGames[this.puuid] = this.spoiledGamesByUser
    window.localStorage.setItem(
      spoiledGamesKey,
      JSON.stringify(this.spoiledGames)
    )

    return true
  }

  get minutesWatchedThreshold () {
    return minutesWatchedThreshold
  }
}

export default new RewardsWatchHistory()
