import { $, Logger, store, erasHeartbeater, render, router } from 'lib'
import locale from 'Common/Locale/Locale'
import util from 'Common/Util/Util'
import watchUtil from 'Container/Watch/Util/WatchUtil'
import videoPlayerUtil from 'Component/Video/Player/Util/VideoPlayerUtil'
import Icon from 'Component/Asset/Icon/Icon'
import rewardsOpting from 'Common/Service/Rewards/Opting/RewardsOpting'
import JumpToButton from 'Component/Video/Controls/JumpToButton'

const scriptUrl = 'https://www.youtube.com/iframe_api'

// TODO: Use a REAL timestamp, game specific (web api or somewhere?)
let updateTimestampId = 0

let handleMultiGameVodBoundaryId = 0

// Updates how often we get currentTime from the player
const updateTimestampInterval = 0.5 * util.times.SECOND
// Updates how often we check the VOD time to see if its past a game boundary due to delayed timestamps
const handleMultiGameVodBoundaryInterval = util.times.SECOND
// Delay between player skips when changing timestamp
const scrubbingThreshold = util.times.SECOND

/**
 * This is a regular class. It is one of the specialized video provider
 * classes used by the main VideoPlayer component to manage specific API code
 * for each provider, in this case, YouTube
 * API: https://developers.google.com/youtube/iframe_api_reference
 */
class VideoPlayerYouTube {
  constructor () {
    this.log = new Logger(this.constructor.name)
    this.state = videoPlayerUtil.states.unstarted
    this.startTimestamp = 0
    this.offset = 0
    this.firstFrameTime = 0
    this.addListeners()
  }

  addListeners () {
    this.stateChangeListener = (event) => {
      if (this.shouldShowJumpToStartButton(event)) {
        if (this.alwaysJumpToStartSettings()) {
          this.jumpToOffset()
        }
        else {
          $('#video-player').append(this.renderJumpToGameStartButton())
        }
      }

      this.state = event.data
      this.log.debug(
        'State changed to',
        videoPlayerUtil.getStateString(this.state)
      )
      store.set('videoPlayer.state', this.state)
      this.clearUpdateTimestamp()
      this.clearHandleMultiGameVodBoundary()

      if (this.state === videoPlayerUtil.states.playing) {
        this.updateTimestamp()
        this.setUpdateTimestampInterval()
        this.setMultiGameVodBoundaryInterval()
      }
    }

    this.onPlayerReady = () => {
      this.log.info('Playing video id', this.uuid)

      if (util.isTouchDevice()) {
        // start touch devices muted, but playing
        this.player.mute()
        this.play()

        // For iPads, there are no volume controls, so the user needs to execute
        // some action to unmute. This is following what youtube does on their
        // own site for iPad (they do not have an API to use their own "touch
        // to unmute" functionality)
        $('#video-player-unmute').on('click', () => {
          this.player.unMute()
          $('#video-player-unmute').remove()
        })
      }
    }

    // this happens once, don't need to be removed
    store.onChange('watch.offset', (offset) => {
      this.offset = offset
    })

    store.onChange('watch.firstFrameTime', (firstFrameTime) => {
      this.firstFrameTime = firstFrameTime
    })

    // this happens once, don't need to be removed
    store.onChange('watch.initialTimestamp', (initialTimestamp) => {
      this.startTimestamp = Date.parse(initialTimestamp)
    })

    // Reset the expected video time whenever a new video is selected
    // to avoid a spurious scrubbing event from being triggered by
    // carrying over the expected time from the previous video
    store.onChange(
      'watch.url',
      this.onUrlUpdate = () => {
        this.videoExpectedTime = 0
      }
    )
  }

  alwaysJumpToStartSettings () {
    const settings = store.get('settings')
    const jumpToStartPref = util.getDeepValue(
      settings,
      'watchSettings.jumpToStart'
    )

    return jumpToStartPref && jumpToStartPref === 'always'
  }

  checkIfScrubbing (videoCurrentTime) {
    this.videoExpectedTime
      = this.videoExpectedTime <= 0
        ? this.getVideoCurrentTime()
        : this.videoExpectedTime + updateTimestampInterval

    const didScrub
      = Math.abs(this.videoExpectedTime - videoCurrentTime) > scrubbingThreshold

    if (didScrub) {
      // Keeping the store variable value as undefined prevents it from being
      // called after the onChange function is re-initialized
      store.set('videoPlayer.dismissJumpButton', undefined)
    }

    this.videoExpectedTime = videoCurrentTime
  }

  shouldShowJumpToStartButton (event) {
    // TODO: fix known bug with 3: when YouTube defaults you to the previous
    // position where you were watching a VOD, it briefly defaults the video
    // time to be 0, which defaults `isBeforeGameStart` to be true, even if
    // the user will then be pointed to their previous position.

    // Should only show the jump to game start button on first video play:
    // 1. Watching a VOD
    // 2. Livestats exist (offset > 0)
    // 3. The current time in the video is before the game start
    // 4. There is not already a JumpToStartButton
    const isVod = this.streamType === watchUtil.streamTypes.vod
    const hasLiveStats = this.offset !== 0 || !!this.firstFrameTime
    const offsetTime = this.firstFrameTime
      ? this.startTimestamp - util.toMilliseconds(this.firstFrameTime)
      : Math.abs(this.offset)
    const isBeforeGameStart
      = this.player && this.getVideoCurrentTime() <= offsetTime
    const buttonDoesNotExist = $('.JumpToButton').length === 0
    // And additionally should only render when:
    // 1. When changing from unstarted to any other state
    // 2. When changing from playing to another playing state
    const unstartedToNotUnstarted
      = this.state === videoPlayerUtil.states.unstarted
      && event.data !== videoPlayerUtil.states.unstarted
    const playingToPlaying
      = this.state === videoPlayerUtil.states.playing
      && event.data === videoPlayerUtil.states.playing

    return (
      isVod
      && hasLiveStats
      && isBeforeGameStart
      && buttonDoesNotExist
      && (unstartedToNotUnstarted || playingToPlaying)
    )
  }

  getState () {
    return this.state
  }

  getVideoCurrentTime () {
    if (!this.player) return 0

    // Try to use getMediaReferenceTime first but the API is
    // undocumented and might disappear from YouTube at any time so we
    // fall-back to getCurrentTime then just zero
    // YouTube's .getCurrentTime() or .getMediaReferenceTime() return
    // fractions of a second like 1.2323231. We transform it into millis
    if (this.player.getMediaReferenceTime) {
      return Math.floor(this.player.getMediaReferenceTime() * 1000)
    }
    else if (this.player.getCurrentTime) {
      return Math.floor(this.player.getCurrentTime() * 1000)
    }

    return 0
  }

  play () {
    this.player.playVideo()
  }

  jumpToOffset () {
    const seconds
      = (this.firstFrameTime
        ? this.startTimestamp - util.toMilliseconds(this.firstFrameTime)
        : Math.abs(this.offset)) / util.times.SECONDS

    this.player.seekTo(seconds, true)
  }

  getTimestamp () {
    const currentTime = this.getVideoCurrentTime()

    if (this.streamType === watchUtil.streamTypes.live) {
      return currentTime + this.offset
    }
    else {
      if (!this.firstFrameTime) {
        return currentTime + this.offset + this.startTimestamp
      }
      else {
        return util.toMilliseconds(this.firstFrameTime) + currentTime
      }
    }
  }

  updateTimestamp () {
    const videoCurrentTime = this.getVideoCurrentTime()

    this.checkIfScrubbing(videoCurrentTime)

    store.set('videoPlayer.timestamp', this.getTimestamp())
  }

  handleMultiGameVodBoundary () {
    const currentTime = this.getVideoCurrentTime()

    // live no timeline vods can be ignored
    if (
      this.streamType === watchUtil.streamTypes.live
      || !this.timeline
      || !this.timelineEvent
    ) {
      return
    }

    this.timeline.forEach((entity) => {
      const isWithinGameBoundary
        = entity.startMillis <= currentTime && currentTime <= entity.endMillis

      if (isWithinGameBoundary) {
        const isCurrentGame
          = this.timelineEvent.matchId === entity.matchId
          && this.timelineEvent.gameNumber === entity.gameNumber
          && this.timelineEvent.gameId === entity.gameId

        if (isCurrentGame) return

        // if this is a different game we're in, set initial timestamp to undefined (preventing spoilers)
        store.set('watch.initialTimestamp', undefined)

        // use redirect to not trigger miniplayer popups
        router.redirect(
          `/vod/${entity.matchId}/${entity.gameNumber}/${this.uuid}`
        )
      }
    })
  }

  setMultiGameVodBoundaryInterval () {
    handleMultiGameVodBoundaryId = window.setInterval(() => {
      this.handleMultiGameVodBoundary()
    }, handleMultiGameVodBoundaryInterval)
  }

  clearHandleMultiGameVodBoundary () {
    window.clearInterval(handleMultiGameVodBoundaryId)
  }

  setUpdateTimestampInterval () {
    updateTimestampId = window.setInterval(() => {
      this.player && this.updateTimestamp()
    }, updateTimestampInterval)
  }

  clearUpdateTimestamp () {
    window.clearInterval(updateTimestampId)
  }

  configureHeartbeater () {
    const heartbeaterConfig = rewardsOpting.getHeartbeaterConfig()

    erasHeartbeater.youtubeHeartbeater.setConfig({
      ...heartbeaterConfig,
      streamId: this.uuid,
      streamType: this.streamType,
      tournamentId: this.tournamentId,
      source: 'youtube',
      onHeartbeatCallback: () =>
        videoPlayerUtil.trackHeartbeat(
          'youtube',
          this.streamType,
          this.tournamentId,
          this.uuid
        )
    })

    erasHeartbeater.youtubeHeartbeater.attach(this.player)
  }

  pause () {
    this.player.pauseVideo()
  }

  remove () {
    this.log.debug('Removing player')
    this.clearUpdateTimestamp()
    if (this.player) {
      erasHeartbeater.youtubeHeartbeater.detach(this.player)

      if (this.player.removeEventListener) {
        this.player.removeEventListener('onReady', this.onPlayerReady)
        this.player.removeEventListener(
          'onStateChange',
          this.stateChangeListener
        )
      }
      this.player.destroy()
      this.player = undefined
    }
  }

  loadById (
    tournamentId,
    uuid,
    streamType,
    startMillis,
    endMillis,
    timelineEvent,
    timeline,
    leagueSlug,
    removePlayer
  ) {
    const isSameVideo = this.uuid === uuid
    const isSameTimelineEvent = this.timelineEvent === timelineEvent

    this.tournamentId = tournamentId
    this.uuid = this.getVideoId(uuid)
    this.streamType = streamType
    this.startMillis = startMillis || 0
    this.endMillis = endMillis
    this.timelineEvent = timelineEvent
    this.timeline = timeline
    this.removePlayer = removePlayer
    this.configureHeartbeater()
    this.log.debug('Loading video with id', this.uuid)

    if (streamType === watchUtil.streamTypes.vod) {
      const startOffset = this.startMillis / util.times.SECONDS

      if (isSameVideo && !isSameTimelineEvent) {
        const time = this.getVideoCurrentTime()

        if (time <= this.startMillis || time >= this.endMillis) {
          this.player.seekTo(startOffset)

          if (this.state !== videoPlayerUtil.states.playing) {
            this.player.playVideo()
          }

          this.clearHandleMultiGameVodBoundary()

          store.set('videoPlayer.timestamp', undefined)
          store.set('watch.initialTimestamp', undefined)
        }
      }

      if (!isSameVideo) {
        this.player.loadVideoById(this.uuid, startOffset)
      }
    }
    else {
      this.player.loadVideoById(this.uuid)
    }
  }

  addToPlaylist () {
    // for compatibility between all player providers
  }

  getVideoId (uuid) {
    // if it is not an youtube uuid, return it as is
    if (uuid.indexOf('youtube.com') === -1) {
      return uuid
    }

    // for urls like:
    // https://www.youtube.com/embed/umLc9wIKppU
    // https://www.youtube.com/embed/FnS39rhaIH8?wmode=transparent&rel=0
    if (uuid.indexOf('embed/') > -1) {
      return uuid.split('embed/')[1].split('?')[0]
    }

    // for urls like:
    // https://www.youtube.com/watch?v=Ky3qQdP38Co
    // https://www.youtube.com/watch?v=8MlWOTiEo6k&wmode=transparent
    if (uuid.indexOf('v=') > -1) {
      return uuid.split('v=')[1].split('&')[0]
    }

    return uuid // in case everything else fails
  }

  setup (
    tournamentId,
    uuid,
    streamType,
    startMillis,
    endMillis,
    timelineEvent,
    timeline,
    leagueSlug,
    removePlayer
  ) {
    this.tournamentId = tournamentId
    this.uuid = this.getVideoId(uuid)
    this.streamType = streamType
    this.startMillis = startMillis || 0
    this.endMillis = endMillis
    this.timelineEvent = timelineEvent
    this.timeline = timeline
    this.leagueSlug = leagueSlug
    this.removePlayer = removePlayer

    if (util.isTouchDevice()) {
      $('#video-player').append(this.renderMutedOverlay())
    }

    window.onYouTubeIframeAPIReady = () => {
      this.player = new window.YT.Player('video-player-youtube', {
        height: '100%',
        width: '100%',
        videoId: this.uuid,
        playerVars: {
          autoplay: 1,
          playsinline: 1,
          modestbranding: 1,
          hl: locale.get(),
          start: this.startMillis / util.times.SECONDS || 0
        },
        events: {
          onReady: this.onPlayerReady,
          onStateChange: this.stateChangeListener
        }
      })

      this.configureHeartbeater()
    }

    // don't load if yt script was injected by other means
    if ($(`script[src="${scriptUrl}"]`).exists() && window.YT) {
      return window.onYouTubeIframeAPIReady()
    }

    // Legacy check, no longer needed, since the check above will cover it
    // don't load yt scripts twice
    // if (
    //   $('#video-player-youtube-script').exists() &&
    //   window.onYouTubeIframeAPIReady &&
    //   window.YT
    // ) {
    //   return window.onYouTubeIframeAPIReady()
    // }

    const script = document.createElement('script')

    script.id = 'video-player-youtube-script'
    script.src = scriptUrl
    $('head').append(script)
  }

  renderMutedOverlay () {
    return render(
      <div id="video-player-unmute">
        <Icon name="muted"/>
      </div>
    )
  }

  renderJumpToGameStartButton () {
    return render(
      <JumpToButton
        onJump={ () => this.jumpToOffset() }
        text={ locale.translate('video.jumpToStartButton.text') }
      />
    )
  }

  render () {
    return <div id="video-player-youtube"/>
  }
}

export default new VideoPlayerYouTube()
