import { erasHeartbeater, Logger, store } from 'lib'
import util from 'Common/Util/Util'
import videoPlayerUtil from 'Component/Video/Player/Util/VideoPlayerUtil'

const scriptUrl = 'https://www.mildom.com/assets/sdk/embed_player.js'

// ID used to cancel current updateTimestamp interval
let updateTimestampId = 0

// Updates how often we get currentTime from the player
const updateTimestampInterval = 0.5 * util.times.SECOND

/**
 * This is a regular class. It is one of the specialized video provider
 * classes used by the main VideoPlayer component to manage specific API code
 * for each provider, in this case, Mildom
 */
class VideoPlayerMildom {
  constructor () {
    this.log = new Logger(this.constructor.name)
    this.state = videoPlayerUtil.states.unstarted
    this.offset = 0
    this.addListeners()
  }

  addListeners () {
    // this happens once, don't need to be removed
    store.onChange('watch.offset', (offset) => {
      this.offset = offset
    })
  }

  getState () {
    return this.state
  }

  setState (state) {
    this.state = state
    this.log.info('State changed to', videoPlayerUtil.getStateString(state))
    store.set('videoPlayer.state', this.state)
    this.clearUpdateTimestamp()

    if (this.state === videoPlayerUtil.states.playing) {
      this.updateTimestamp()
      this.setUpdateTimestampInterval()
    }
  }

  play () {
    this.player && this.player.play()
  }

  pause () {
    this.player && this.player.pause()
  }

  getVideoCurrentTime () {
    if (this.player && this.player.getVideoCurrentTime) {
      // TODO: This is broken on the Mildom player
      // Return as unix timestamp in seconds
      // return this.player.getVideoCurrentTime()
    }

    // Return wall-time because Mildom only supports Livestreams
    // with no scrubbing.
    return Date.now() + this.offset
  }

  updateTimestamp () {
    const timestamp = this.getVideoCurrentTime()

    store.set('videoPlayer.timestamp', timestamp)
  }

  setUpdateTimestampInterval () {
    updateTimestampId = window.setInterval(() => {
      this.player && this.updateTimestamp()
    }, updateTimestampInterval)
  }

  clearUpdateTimestamp () {
    window.clearInterval(updateTimestampId)
  }

  loadById (tournamentId, uuid, streamType) {
    this.tournamentId = tournamentId
    this.uuid = uuid
    this.streamType = streamType
    this.configureHeartbeater()
    this.log.debug('Loading video with id', this.uuid)
    this.remove()
    this.createPlayer()
  }

  configureHeartbeater () {
    erasHeartbeater.unsupportedPlayerHeartbeater.setUnsupportedPlayerConfig({
      source: 'mildom'
    })
  }

  addToPlaylist () {
    // for compatibility between all player providers
  }

  setup (tournamentId, uuid, streamType) {
    this.tournamentId = tournamentId
    this.uuid = uuid
    this.streamType = streamType

    window.require([scriptUrl], (player) => {
      if (!window.Mildom) {
        window.Mildom = player
      }
      this.createPlayer()
      this.configureHeartbeater()
    })
  }

  createPlayer () {
    this.player = new window.Mildom.Player('video-player-mildom', {
      height: '100%',
      width: '100%',
      roomId: this.uuid
    })

    this.addPlayerStateListeners()

    store.set('videoPlayer.timestamp', this.getVideoCurrentTime())
    this.configureHeartbeater()
  }

  addPlayerStateListeners () {
    this.stateChangeListener = (state) => {
      switch (state) {
        case window.Mildom.Player.PLAY:
          this.setState(videoPlayerUtil.states.playing)
          break
        case window.Mildom.Player.PAUSE:
          this.setState(videoPlayerUtil.states.paused)
          break
        case window.Mildom.Player.ENDED:
          this.setState(videoPlayerUtil.states.ended)
          break
      }
    }
    this.player.onStateChange(this.stateChangeListener)
  }

  remove () {
    this.log.debug('Removing player')
    this.clearUpdateTimestamp()
    if (this.player) {
      this.stateChangeListener
        && this.player.removeOnStateChangeListener(this.stateChangeListener)
      // This is a typo in the SDK
      this.player.destory()
      this.player = undefined
    }
  }

  render () {
    return <div id="video-player-mildom"/>
  }
}

export default new VideoPlayerMildom()
