import { erasHeartbeater, Logger } from 'lib'
import locale from 'Common/Locale/Locale'
import videoPlayerUtil from 'Component/Video/Player/Util/VideoPlayerUtil'

const scriptUrl = 'https://www.nimo.tv/sdk/embed-player-0.3.2.min.js'

const nimoDefaultLang = 1033
const nimoLangMap = {
  'ar-AE': 1025,
  'cs-CZ': null,
  'de-DE': null,
  'el-GR': null,
  'en-AU': 1033,
  'en-GB': 1033,
  'en-PH': 1033,
  'en-PL': 1033,
  'en-SG': 1033,
  'en-US': 1033,
  'es-AR': 1034,
  'es-ES': 1034,
  'es-MX': 1034,
  'fr-FR': null,
  'hu-HU': null,
  'id-ID': 1057,
  'it-IT': null,
  'ja-JP': 1041,
  'ko-KR': null,
  'ms-MY': null,
  'pl-PL': null,
  'pt-BR': 1046,
  'ro-RO': null,
  'ru-RU': 1049,
  'th-TH': 1054,
  'tr-TR': 1055,
  'vn-VN': null,
  'zh-CN': 1028,
  'zh-MY': 1028,
  'zh-TW': 1028
}

/*
 * Nimo Player
 */

class VideoPlayerNimo {
  constructor () {
    this.log = new Logger(this.constructor.name)
    this.state = videoPlayerUtil.states.unstarted
  }

  setup (tournamentId, uuid, streamType) {
    this.tournamentId = tournamentId
    this.uuid = uuid
    this.streamType = streamType

    window.require([scriptUrl], (player) => {
      if (!window.NimoTV) {
        window.NimoTV = player
      }
      this.createPlayer()
    })
  }

  createPlayer () {
    const nimoLangCode = nimoLangMap[locale.get()] || nimoDefaultLang

    const config = {
      width: '100%',
      height: '100%',
      resourceId: this.uuid,
      lang: nimoLangCode
    }

    this.player = new window.NimoTV.Player('video-player-nimo', config)
    this.addVideoEventListeners()
    this.configureHeartbeater()
    this.play()
  }

  getState () {
    return this.state
  }

  setState (value) {
    this.state = value
  }

  play () {
    this.state = videoPlayerUtil.states.playing
    this.player.play()
  }

  pause () {
    this.state = videoPlayerUtil.states.paused
    this.player.pause()
  }

  remove () {
    if (this.player) {
      this.removeVideoEventListeners()
      this.player.destroy()
    }
  }

  addVideoEventListeners () {
    if (!this.playEvent) {
      this.playEvent = this.setState.bind(this, videoPlayerUtil.states.playing)
      this.pauseEvent = this.setState.bind(this, videoPlayerUtil.states.paused)
      this.endedEvent = this.setState.bind(this, videoPlayerUtil.states.ended)

      this.player.on(window.NimoTV.Player.PLAY, this.playEvent)
      this.player.on(window.NimoTV.Player.PAUSED, this.pauseEvent)
      this.player.on(window.NimoTV.Player.ENDED, this.endedEvent)
    }
    else {
      this.log.warn('Nimo player video events already initialized!')
    }
  }

  removeVideoEventListeners () {
    this.player.off(window.NimoTV.Player.PLAY, this.playEvent)
    this.player.off(window.NimoTV.Player.PAUSED, this.pauseEvent)
    this.player.off(window.NimoTV.Player.ENDED, this.endedEvent)

    this.playEvent = this.pauseEvent = this.endedEvent = null
  }

  configureHeartbeater () {
    erasHeartbeater.unsupportedPlayerHeartbeater.setUnsupportedPlayerConfig({
      source: 'nimo'
    })
  }

  loadById (tournamentId, uuid, streamType) {
    this.tournamentId = tournamentId
    this.uuid = uuid
    this.streamType = streamType
    this.configureHeartbeater()
    this.remove()
    this.setup(tournamentId, uuid, streamType)
  }

  getVideoCurrentTime () {
    return Date.now()
  }

  render () {
    return <div id="video-player-nimo"/>
  }
}

export default new VideoPlayerNimo()
