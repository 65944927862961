import { Component, Logger, router, store, $ } from 'lib'
import VodsFilter from 'Component/Vods/Filter/VodsFilter'
import VodsList from 'Component/Vods/List/VodsList'
import VodsTopNav from 'Component/Vods/TopNav/VodsTopNav'
import InformLoading from 'Component/Inform/Loading/InformLoading'
import InformBubble from 'Component/Inform/Bubble/InformBubble'
import relApi from 'Common/Service/RelApi/RelApi'
import util from 'Common/Util/Util'
import google from 'Common/Service/Google/Google'
import {
  leaguePriority,
  sortLeaguesByPriority
} from '../../Common/Service/RelApi/RelApi'
import analytics from 'Common/Service/Analytics/Analytics'

const vodLeagueKey = 'selected-league-vods'

class Vods extends Component {
  componentDidMount () {
    this.log = new Logger(this.constructor.name)
    this.addEventListeners()
    this.fetchVodsLeagues()
  }

  componentWillUnmount () {
    store.remove('vods.toggleFilter')
    $(document.body).removeClass('no-scroll-small')
  }

  componentDidUpdate (prevProps, prevState) {
    window.scrollTo(0, 0)
    if (prevState.filterActive !== this.state.filterActive) {
      $(document.body).toggleClass('no-scroll-small', !!this.state.filterActive)
    }
  }

  sendMetrics (selectedLeague) {
    const leagueIdFromStorage = window.localStorage.getItem(vodLeagueKey)

    google.pushSelectedLeagues([selectedLeague], !!leagueIdFromStorage, 'vods')
  }

  addEventListeners () {
    store.onChange(
      'vods.toggleFilter',
      this.vodsToggleFilterListener = (next, prev) => {
        if (next !== prev) {
          this.setState({ filterActive: next })
        }
      }
    )

    this.tournamentChangeHandler = (tournamentSlug) => {
      const selectedTournament = util.getObjectFromKey(
        'slug',
        tournamentSlug,
        this.state.tournaments
      )

      this.setState({ selectedTournament })
      this.fetchVodsSchedule(selectedTournament.id)
    }

    this.leagueChangeHandler = (leagueSlug) => {
      const selectedLeague = util.getObjectFromKey(
        'slug',
        leagueSlug,
        this.state.leagues
      )

      analytics.trackEvent('vod_filter_applied', { league: leagueSlug })

      this.sendMetrics(selectedLeague)

      // reset matches to show the loading spinner again
      this.setState({
        selectedLeague,
        matches: undefined,
        nextUnstartedMatch: undefined
      })
      window.localStorage.setItem(vodLeagueKey, selectedLeague.id)
      this.fetchVodsTournaments(selectedLeague.id)
    }
  }

  fetchVodsLeagues () {
    return relApi
      .fetchLeagues()
      .then((json) => {
        const leagues = json.leagues.sort((leagueA, leagueB) =>
          sortLeaguesByPriority(leagueA, leagueB)
        )
        const selectedLeague = this.getSelectedLeague(leagues)

        this.sendMetrics(selectedLeague)

        this.setState({ selectedLeague, leagues })
        this.fetchVodsTournaments(selectedLeague.id)
      })
      .catch((error) => {
        this.setState({ error })
        this.log.error(error)
      })
  }

  fetchVodsTournaments (leagueId) {
    return relApi
      .fetchTournaments(leagueId)
      .then((json) => {
        const tournaments = json[0].tournaments
        const selectedTournament = this.getSelectedTournament(tournaments)

        this.setState({ selectedTournament, tournaments })
        this.fetchVodsSchedule(selectedTournament.id)
      })
      .catch((error) => {
        this.setState({ error })
        this.log.error(error)
      })
  }

  fetchVodsSchedule (tournamentId) {
    return relApi
      .fetchVods(tournamentId)
      .then(({ events, nextUnstartedMatch }) => {
        this.setState({ matches: events })
        this.setState({ nextUnstartedMatch })
        // after updating leagues -> tournaments -> matches we update the url
        this.updateUrl()
      })
      .catch((error) => {
        this.setState({ error })
        this.log.error(error)
      })
  }

  updateUrl () {
    const url
      = '/vods/'
      + this.state.selectedLeague.slug
      + '/'
      + this.state.selectedTournament.slug

    router.updateUrl(url)
  }

  getSelectedLeague (leagues) {
    const leagueSlug = router.param('leagueSlug')
    const leagueFromParam
      = leagueSlug && util.getObjectFromKey('slug', leagueSlug, leagues)

    if (leagueFromParam) {
      return leagueFromParam
    }

    const priorityZeroLeague = leagues.find(
      (league) =>
        league.displayPriority?.status === leaguePriority.FORCE_SELECTED
        && league.displayPriority?.position === 0
    )

    if (priorityZeroLeague) {
      return priorityZeroLeague
    }

    const leagueIdFromStorage = window.localStorage.getItem(vodLeagueKey)

    if (leagueIdFromStorage) {
      const leagueFromStorage = util.getObjectFromKey(
        'id',
        leagueIdFromStorage,
        leagues
      )

      if (leagueFromStorage) {
        return leagueFromStorage
      }
    }

    return leagues[0] // expect that leagues are sorted by priority
  }

  getSelectedTournament (tournaments) {
    const tournamentSlug = router.param('tournamentSlug')
    const tournamentFromParam
      = tournamentSlug
      && util.getObjectFromKey('slug', tournamentSlug, tournaments)

    return tournamentFromParam || tournaments[0]
  }

  renderErrorOrLoading (error) {
    return (
      <main class={ this.constructor.name }>
        { error ? (
          <InformBubble theme="error" icon="error">
            { error.message }
          </InformBubble>
        )
          : <InformLoading/>
        }
      </main>
    )
  }

  render () {
    if (!this.state.leagues || !this.state.tournaments || this.state.error) {
      return this.renderErrorOrLoading(this.state.error)
    }

    const filterActiveClass = this.state.filterActive && 'filter-active'
    const componentClasses = util.classNames(
      this.constructor.name,
      filterActiveClass
    )

    return (
      <main class={ componentClasses }>
        <VodsTopNav
          selectedLeague={ this.state.selectedLeague }
          tournaments={ this.state.tournaments }
          selectedTournament={ this.state.selectedTournament }
          tournamentChangeHandler={ this.tournamentChangeHandler }
          filterActiveClass={ filterActiveClass }
        />
        <div class="sidebar">
          <VodsFilter
            leagues={ this.state.leagues }
            selectedLeague={ this.state.selectedLeague }
            leagueChangeHandler={ this.leagueChangeHandler }
          />
        </div>
        <div class="list">
          <VodsList
            matches={ this.state.matches }
            nextUnstartedMatch={ this.state.nextUnstartedMatch }
            selectedLeague={ this.state.selectedLeague }
            tournaments={ this.state.tournaments }
            selectedTournament={ this.state.selectedTournament }
          />
        </div>
      </main>
    )
  }
}

export default Vods
