import { Logger, store, erasHeartbeater, $ } from 'lib'
import util from 'Common/Util/Util'
import rewardsOpting from 'Common/Service/Rewards/Opting/RewardsOpting'
import videoPlayerUtil from 'Component/Video/Player/Util/VideoPlayerUtil'

const scriptUrl = 'https://cdn.trovo.live/embed/iframeApi.js'

let updateTimestampId = 0

// Updates how often we get currentTime from the player
const updateTimestampInterval = 0.5 * util.times.SECOND

class VideoPlayerTrovo {
  constructor () {
    this.log = new Logger(this.constructor.name)
    this.state = videoPlayerUtil.states.unstarted
    this.offset = 0
    this.addListeners()
  }

  addListeners () {
    // this happens once, don't need to be removed
    store.onChange('watch.offset', (offset) => {
      this.offset = offset
    })
  }

  getState () {
    return this.state
  }

  setState (state) {
    this.state = state
    this.log.info('State changed to', videoPlayerUtil.getStateString(state))
    store.set('videoPlayer.state', this.state)
    this.clearUpdateTimestamp()

    if (this.state === videoPlayerUtil.states.playing) {
      this.updateTimestamp()
      this.setUpdateTimestampInterval()
    }
  }

  updateTimestamp () {
    store.set('videoPlayer.timestamp', this.getVideoCurrentTime())
  }

  setUpdateTimestampInterval () {
    updateTimestampId = window.setInterval(() => {
      this.updateTimestamp()
    }, updateTimestampInterval)
  }

  clearUpdateTimestamp () {
    window.clearInterval(updateTimestampId)
  }

  play () {
    this.player.play()
  }

  pause () {
    this.player.pause()
  }

  remove () {
    this.log.debug('Removing player')
    this.clearUpdateTimestamp()
    if (this.player) {
      erasHeartbeater.trovoHeartbeater.detach(this.player)
      // Removes all event handlers
      this.player.destroy()
      $('#video-player-trovo').remove()
      this.player = undefined
    }
  }

  configureHeartbeater () {
    const heartbeaterConfig = rewardsOpting.getHeartbeaterConfig()

    erasHeartbeater.trovoHeartbeater.setConfig({
      ...heartbeaterConfig,
      streamId: this.uuid,
      streamType: this.streamType,
      tournamentId: this.tournamentId,
      source: 'trovo',
      onHeartbeatCallback: () =>
        videoPlayerUtil.trackHeartbeat(
          'trovo',
          this.streamType,
          this.tournamentId,
          this.uuid
        )
    })

    erasHeartbeater.trovoHeartbeater.attach(this.player)
  }

  loadById (tournamentId, uuid, streamType) {
    this.tournamentId = tournamentId
    this.uuid = uuid
    this.streamType = streamType
    this.log.debug('Loading video with id', this.uuid)
    this.createPlayer()
  }

  addToPlaylist () {
    // for compatibility between all player providers
  }

  getVideoCurrentTime () {
    if (!this.player) return 0

    return this.player.getDuration()
  }

  setup (tournamentId, uuid, streamType) {
    this.tournamentId = tournamentId
    this.uuid = uuid
    this.streamType = streamType

    window.require([scriptUrl], () => {
      this.createPlayer()
    })
  }

  createPlayer () {
    this.player = new window.Trovo.TrovoPlayer('video-player-trovo', {
      width: '100%',
      height: '100%',
      streamername: this.uuid,
      enablejsapi: true,
      sdist: 10210001001,
      events: {
        onStateChange: (state) => {
          switch (state.toUpperCase()) {
            case 'PLAYING':
              this.setState(videoPlayerUtil.states.playing)
              break
            case 'PAUSE':
              this.setState(videoPlayerUtil.states.paused)
              break
            case 'STOP':
              this.setState(videoPlayerUtil.states.ended)
              break
            case 'IDLE':
              this.setState(videoPlayerUtil.states.unstarted)
              break
          }
        }
      }
    })

    store.set('videoPlayer.timestamp', this.getVideoCurrentTime())
    this.configureHeartbeater()
  }

  render () {
    return <div id="video-player-trovo"/>
  }
}

export default new VideoPlayerTrovo()
